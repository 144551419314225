import React, { useEffect } from "react";
import styled from "styled-components";
import Menu from "../../Component/MenuTokenization/MenuTokenization";
import Layout from "../../Component/Layout/Layout";
import { IconButton } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; // Import the logout icon
import Namecontainer from "../../Component/Namecontainer/Namecontainer";
import { Button, Typography, Grid } from "@mui/material";
import PaymentHistoryTable from "../../Component/PaymentHistory/PaymentHistoryTable";

export default function PaymentHistory() {
  
  return (
    <div>
     
      <Layout>
        <PaymentHistoryTable/>

      </Layout>
      
    </div>
  );
}

