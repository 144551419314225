
import React, { useEffect } from "react";
import Desactivarcargo from "../../Component/Desactivarcargo/Desactivarcargo";
import Layout from "../../Component/Layout/Layout";

function Desactivarcargosrecurrentes() {
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  return (
    <Layout>
      <Desactivarcargo />
    </Layout>
  );
}

export default Desactivarcargosrecurrentes;
