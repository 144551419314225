import React, { useState } from "react";
import styled from "styled-components";

function Namecontainer() {
  //const [username, setusername] = useState("");
  const username = localStorage.getItem("user_name");
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };
  return (
    <MenuContainerWrapper>
      <UserName>Hello, {username}</UserName>
      <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
    </MenuContainerWrapper>
  );
}

export default Namecontainer;
const MenuContainerWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid green;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

const UserName = styled.span`
  font-weight: bold;
`;

const LogoutButton = styled.button`
  background-color: rgb(174, 203, 53);
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
`;
