import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  TableBodyCell,
  TableComponent,
  TableBody,
  TableHeaderCell,
  // TableHeaderModification2,
  //NewTableHeader,
  ModifyNewTableHeader,
  // NewRow,
  NewRowModi,
} from "../TableStyle/Tablestyle";
import BottomBar from "../MisTarjetas/BottomBar";
import { getAutoPaymentById } from "../../api/Axios";
import { Link } from "react-router-dom";

function Modificarcargosrecurrentesdesktop() {
  const [autoPaymentList, setAutoPaymentList] = useState([]);
  const userId = localStorage.getItem("user_id");
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  useEffect(() => {
    getAutoPaymentById(userId)
      .then((res) => {
        setAutoPaymentList(res.data.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Grid
        container
        columns={{ xs: 4, md: 12 }}
        sx={{
          padding: { xs: "10px 1rem", md: "20px 5.625rem" },
        }}
      >
        {/* title */}
        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{
              fontSize: ["2rem", "2.5rem"],
              fontWeight: "900",
              color: "#0A6644",
              fontStyle: "normal",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            Modificar cargos recurrentes
          </Typography>
        </Grid>
        {/* description */}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: ["1.5rem", "2rem"],
              fontWeight: "390px",
              color: "#0A6644",
              fontStyle: "normal",
              maxWidth: "77.835vw",
            }}
          >
            Pulsa sobre la póliza para modificar el cargo recurrente
          </Typography>

          {/* <Typography
           variant="p"
           sx={{
             fontSize: ["1rem", "1.3rem"],
             fontWeight: "200",
             color: "#0A6644",
             fontStyle: "normal",
             justifyContent: "center",
             alignItems: "center",
             display: "flex",
           }}
          >
          <lable style={{paddingTop: "20px"}}>Asegurado : {autoPaymentList.length > 0 ? (
            autoPaymentList[0].nombre
          ) : (
            "No hay pagos recurrentes"
          )}</lable>  
        
          </Typography> */}
          
        </Grid>
       
       
        <Grid sx={{ width: "100%" }}>
          <TableComponent>
            <ModifyNewTableHeader>
              <TableHeaderCell>Póliza </TableHeaderCell>
              <TableHeaderCell>Ramo</TableHeaderCell>
              <TableHeaderCell>Monto</TableHeaderCell>
              <TableHeaderCell>Día cargo</TableHeaderCell>
              <TableHeaderCell>Nombre del asegurado</TableHeaderCell>
              <TableHeaderCell>No. tarjeta</TableHeaderCell>
              <TableHeaderCell>Acción</TableHeaderCell>
            </ModifyNewTableHeader>
            <TableBody>
              {autoPaymentList.length > 0 ? (
                autoPaymentList.map((data) => {
                  return (
                    <Link
                      to={`/modificarcargosrecurrentes/form/${data.policy_no}/${data.date}/${data.amount}/${data.token}`}
                    >
                      <NewRowModi>
                        {/* <TableBodyCell>{data.policy_no}</TableBodyCell>
                        <TableBodyCell>{data.ramo}</TableBodyCell>
                        <TableBodyCell>${data.amount}</TableBodyCell>
                        <TableBodyCell>{data.nombre}</TableBodyCell>
                        <TableBodyCell>{data.date}</TableBodyCell> */}
                        <TableBodyCell
                          style={{
                            whiteSpace: "normal",
                            overflow: "visible",
                            textOverflow: "unset",
                          }}
                        >
                          {data.policy_no}
                        </TableBodyCell>
                        <TableBodyCell
                          style={{
                            whiteSpace: "normal",
                            overflow: "visible",
                            textOverflow: "unset",
                          }}
                        >
                          {data.ramo}
                        </TableBodyCell>
                        <TableBodyCell
                          style={{
                            whiteSpace: "normal",
                            overflow: "visible",
                            textOverflow: "unset",
                          }}
                        >
                          ${data.amount}
                        </TableBodyCell>
                        <TableBodyCell
                          style={{
                            whiteSpace: "normal",
                            overflow: "visible",
                            textOverflow: "unset",
                          }}
                        >
                          {data.date}
                        </TableBodyCell>
                        <TableBodyCell
                          style={{
                            whiteSpace: "normal",
                            overflow: "visible",
                            textOverflow: "unset",
                          }}
                        >
                          {data.nombre}
                        </TableBodyCell>
                        <TableBodyCell
                          style={{
                            whiteSpace: "normal",
                            overflow: "visible",
                            textOverflow: "unset",
                          }}
                        >
                          {data.cardDetails}
                        </TableBodyCell>
                        <TableBodyCell
                          style={{
                            whiteSpace: "normal",
                            overflow: "visible",
                            textOverflow: "unset",
                          }}
                          onClick={() =>
                            (window.location.href = `/modificarcargosrecurrentes/form/${data.policy_no}/${data.date}/${data.amount}/${data.token}`)
                          }
                        >
                          <EditIcon style={{ color: "green" }} />
                        </TableBodyCell>
                      </NewRowModi>
                    </Link>
                  );
                })
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  No hay pagos recurrentes
                </h1>
              )}
            </TableBody>
          </TableComponent>
        </Grid>
        <Typography
          variant="p"
          sx={{
            fontSize: ["1rem", "1.5rem"],
            fontWeight: 100,
            fontStyle: "normal",
            color: "#0A6644",
            paddingTop: "20px",
          }}
        >
          {/* *Corresponde al día de vencimiento de cada cuota */}
        </Typography>
      </Grid>
      <BottomBar />
    </>
  );
}

export default Modificarcargosrecurrentesdesktop;
