import React, { useState, useEffect } from "react";
import { getAllPaymentHistory } from "../../api/Axios";
import { Grid, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Modal,
  ModalContent,
  ModalText,
  ModalButton,
  ModalButtonContainerDelete,
  ModalButtonContainer,
} from "../TableStyle/Tablestyle";
import {
  TableBodyCell,
  TableComponent,
  TableBody,
  TableHeaderCell,
  NewTableHeaderHistory,
  NewRowHistory,
} from "../TableStyle/Tablestyle";

function PaymentHistoryTable(props) {
  const [originalHistory, setOriginalHistory] = useState([]);
  const [history, setHistory] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const GetAllPaymentHistory = async () => {
    try {
      const data = await getAllPaymentHistory();

      if (data.status) {
        console.log("sample", data.data);
        setHistory(data.data.result);
        setOriginalHistory(data.data.result);
      } else {
        console.log("No samples found:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    GetAllPaymentHistory();
  }, []);

  // const formatDate = (timestamp) => {
  //   const date = new Date(timestamp);
  //   const day = date.getDate().toString().padStart(2, '0');
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const year = date.getFullYear();
  //   return `${day}-${month}-${year}`;
  // };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleFilter = () => {
    const formattedStartDate = startDate ? `${startDate}T00:00:00` : null;
    const formattedEndDate = endDate ? `${endDate}T23:59:59` : null;

    const filteredHistory = originalHistory.filter((item) => {
      const itemDate = new Date(item.date);

      return (
        (!formattedStartDate || itemDate >= new Date(formattedStartDate)) &&
        (!formattedEndDate || itemDate <= new Date(formattedEndDate)) &&
        (!selectedStatus || item.payment_status === selectedStatus)
      );
    });

    setHistory(filteredHistory);
  };

  return (
    <div>
      <Grid
        container
        columns={{ xs: 4, md: 12 }}
        sx={{
          padding: { xs: "10px 1rem", md: "20px 5.625rem" },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: ["1.5rem", "2rem"],
              fontWeight: "390px",
              color: "#0A6644",
              fontStyle: "normal",
              maxWidth: "77.835vw",
            }}
          >
            Historial De Pagos
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          <label htmlFor="start-date-picker">Fecha incial: </label>
          <input
            type="date"
            id="start-date-picker"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ marginRight: "1rem" }}
          />

          <label htmlFor="end-date-picker">Fecha final: </label>
          <input
            type="date"
            id="end-date-picker"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={{ marginRight: "1rem" }}
          />

          <label htmlFor="status-filter">Estado de pago: </label>
          <select
            id="status-filter"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            style={{ marginRight: "1rem" }}
          >
            <option value="">Todos</option>
            <option value="payment success">Pago exitoso</option>
            <option value="payment not success">Pago no exitoso</option>
            <option value="Request Buy payment not success">Compra no exitosa</option>
            <option value="validate payment not success">Validación no exitosa</option>
          </select>

          <button onClick={handleFilter} style={{ borderRadius: "4px" }}>
            Filtrar
          </button>
        </Grid>

        <Grid sx={{ width: "100%" }}>
          <TableComponent>
            <NewTableHeaderHistory>
              <TableHeaderCell>Usuario</TableHeaderCell>
              <TableHeaderCell>Email</TableHeaderCell>
              <TableHeaderCell>Monto</TableHeaderCell>
              <TableHeaderCell>Fecha</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
            </NewTableHeaderHistory>

            {history.length > 0 ? (
              history.map((item) => (
                <TableBody key={item.id}>
                  <NewRowHistory
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#f5f5f5",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                  >
                    <TableBodyCell>{item.user_id}</TableBodyCell>
                    <TableBodyCell>{item.email}</TableBodyCell>
                    <TableBodyCell>${item.amount}</TableBodyCell>
                    <TableBodyCell>{formatDate(item.date)}</TableBodyCell>
                    <TableBodyCell>{item.payment_status}</TableBodyCell>
                  </NewRowHistory>
                </TableBody>
              ))
            ) : (
              <h1 style={{ textAlign: "center" }}>
                No hay datos de pago automático disponibles.
              </h1>
            )}
          </TableComponent>
        </Grid>
      </Grid>
    </div>
  );
}

export default PaymentHistoryTable;
