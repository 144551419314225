import React, { useEffect, useRef, useState } from "react";
import BottomBar from "../MisTarjetas/BottomBar";
import { Grid, Typography } from "@mui/material";
import {
  Row,
  TableBodyCell,
  TableComponent,
  TableHeader,
  TableBody,
  TableHeaderCell,
  DuiWrapper,
  Modal,
  ModalContent,
  ModalText,
  ModalButtonContainer,
  ModalButton,
} from "../TableStyle/Tablestyle";
import {
  getPolicy,
  getallAutoPayment,
  validateautopayment,
} from "../../api/Axios";
import { parseString } from "xml2js";

function Cargosrecurrentesdesktop() {
  const [data, setData] = useState([]);
  const [dui, setDui] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [duierror, setDuiError] = useState(false);
  const tableContainerRef = useRef(null);
  const [isOpenModal, setIsopenModal] = useState(false);
  const [allautopaymentdata, setAllAutoPaymentdata] = useState([]);
  const [disable, setDiasable] = useState(false);
  // let idProcessPayment;
  const xml2js = require("xml2js");
  const [idProcessPayment, setIdProcessPayment] = useState(null);
  const [soapResponse, setSoapResponse] = useState("");
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);

  const handleDuiChange = (e) => {
    let input = e.target.value;

    // Remove any non-numeric characters
    input = input.replace(/\D/g, "");

    // Apply the formatting
    if (input.length >= 8) {
      input = input.slice(0, 8) + "-" + input.charAt(8);
    }
    setDui(input);
  };

  const HandleClick = () => {
    setDuiError(false);
    setError(false);
    setIsOpen(false);
    if (!dui) {
      setError(true);
    } else {
      setError(false);
      try {
        setDiasable(true);
        getPolicy(dui)
          .then((res) => {
            console.log(res);
            const data = res.data.data;
            // setSoapResponse(data);
            console.log(data);
            const parser = new xml2js.Parser();
            parser.parseString(data, (err, result) => {
              if (err) {
                console.error("Error parsing XML:", err);
                return;
              }
              const id =
                result["s:Envelope"]["s:Body"][0][
                  "SearchPoliciesToPayResponse"
                ][0]["SearchPoliciesToPayResult"][0]["b:IdProcessPayment"][0];
              setIdProcessPayment(id);
            });
            parseString(data, (error, result) => {
              if (error) {
                console.error("Error parsing XML:", error);
                alert(error);
              } else {
                try {
                  const jsonDataString =
                    result["s:Envelope"]["s:Body"]?.[0]?.[
                      "SearchPoliciesToPayResponse"
                    ]?.[0]?.["SearchPoliciesToPayResult"]?.[0]?.["b:Data"]?.[0];

                  if (jsonDataString !== undefined) {
                    const jsonData = JSON.parse(jsonDataString);
                    const dataset = jsonData.Polizas;
                    setData(dataset);
                    console.log(dataset);
                  } else {
                    console.error("jsonDataString is undefined.");
                    // Handle the error condition here, possibly setting an error state.
                  }
                  setDuiError(false);
                  setIsOpen(true);
                  setDiasable(false);
                } catch (jsonParseError) {
                  console.error("Error parsing JSON:", jsonParseError);
                  setDuiError(true);
                  setDiasable(false);
                }
              }
            });
          })
          .catch((err) => {
            console.log(err);
            setDiasable(false);
          });
      } catch (fetchError) {
        console.error("Error fetching policy:", fetchError);
        setDiasable(false);
      }
    }
  };
  // console.log(idProcessPayment);
  useEffect(() => {
    getallAutoPayment()
      .then((res) => {
        setAllAutoPaymentdata(res.data.result);
        console.log(res.data.result.policy_no);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //const idd = 100;
  //const idd = parseInt(data[0].AsegCod, 10);
  const HandleNavigate = async (
    asegcode,
    polnro,
    ramdesc,
    primatotal,
    day,
    nombaseg,
    rowIndex,
    cuotaData
  ) => {
    const polnroExists = allautopaymentdata.some(
      (item) => item.policy_no === polnro
    );
    if (polnroExists) {
      setIsopenModal(true);
    } else {
      try {
        const value = {
          idPees: {
            FeesToPay: [
              {
                IdPolEndo: data[0].Cuotas[0].IdPolEndo,
                IdPol: data[0].Cuotas[0].IdPol,
                IdPag: data[0].Cuotas[0].IdPag,
                NoCuota: data[0].Cuotas[0].NoCuota,
              },
            ],
          },
          id: parseInt(data[0].AsegCod, 10),
        };
        const arrayString = JSON.stringify(cuotaData);
        console.log(value);
        const res = await validateautopayment(value);
        window.location.href = `/activarcargosrecurrentes/${asegcode}/${polnro}/${ramdesc}/${primatotal}/${day}/${nombaseg}/${idProcessPayment}/${rowIndex}/${dui}/${arrayString}`;
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (isOpen && tableContainerRef.current) {
      tableContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isOpen]);

  return (
    <>
      <Grid
        style={{ height: "100%" }}
        columns={{ xs: 4, md: 12 }}
        sx={{
          padding: { xs: "10px 1rem", md: "20px 5.625rem" },
        }}
      >
        {/* title */}
        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{
              fontSize: ["2rem", "2.5rem"],
              fontWeight: "900",
              color: "#0A6644",
              fontStyle: "normal",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            Activar cargos recurrentes
          </Typography>
        </Grid>
        {/* description */}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: ["1.5rem", "2rem"],
              fontWeight: "390px",
              color: "#0A6644",
              fontStyle: "normal",
              maxWidth: "77.835vw",
            }}
          >
            Gestiona los cargos recurrentes de la cuenta.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DuiWrapper>
            <Typography
              variant="p"
              sx={{
                padding: "10px",
                fontSize: ["1.5rem", "2rem"],
                fontWeight: "900",
                color: "#0A6644",
                fontStyle: "normal",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              Ingresa tu número de DUI:
            </Typography>
            <input
              type="text"
              value={dui}
              onChange={handleDuiChange}
              placeholder="99999999-9"
            />
            <br />
            <button
              onClick={HandleClick}
              style={disable ? { opacity: "0.5" } : { opacity: "1" }}
              disabled={disable}
            >
              CONTINUAR
            </button>
          </DuiWrapper>
        </Grid>
        {isOpen && (
          <>
            <Grid sx={{ width: "100%" }}>
              <div ref={tableContainerRef}>
                <TableComponent>
                  <TableHeader>
                    <TableHeaderCell>Póliza </TableHeaderCell>
                    <TableHeaderCell>Ramo</TableHeaderCell>
                    <TableHeaderCell>Monto</TableHeaderCell>
                    <TableHeaderCell> Vence*</TableHeaderCell>
                  </TableHeader>
                  {data.length ? (
                    <TableBody>
                      {data.map((data, index) => {
                        // Check if data.Cuotas and data.Cuotas[0] exist before accessing FechaVenc
                        const fechaVenc =
                          data.Cuotas && data.Cuotas[0]
                            ? data.Cuotas[0].FechaVenc
                            : "";

                        // Split the date only if it's not empty
                        const dateParts = fechaVenc ? fechaVenc.split("/") : [];
                        const day =
                          dateParts.length > 0 ? parseInt(dateParts[0], 10) : 0;
                        const { IdPolEndo, IdPol, IdPag, NoCuota } =
                          data.Cuotas[0];
                        const cuotaData = [IdPolEndo, IdPol, IdPag, NoCuota];
                        return (
                          <Row
                            onClick={() => {
                              HandleNavigate(
                                data.AsegCod,
                                data.PolNro,
                                data.RamDesc,
                                data.Cuotas[0].PrimaTotal,
                                day,
                                data.NombAseg,
                                index,
                                cuotaData
                              );
                            }}
                            key={data.PolNro}
                          >
                            <TableBodyCell>{data.PolNro}</TableBodyCell>
                            <TableBodyCell>{data.RamDesc}</TableBodyCell>
                            <TableBodyCell>
                              ${data.Cuotas[0].PrimaTotal}
                            </TableBodyCell>
                            <TableBodyCell>{day}</TableBodyCell>
                          </Row>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <Row>
                        <TableBodyCell>No hay datos de póliza</TableBodyCell>
                      </Row>
                    </TableBody>
                  )}
                </TableComponent>
              </div>
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: ["1rem", "1.5rem"],
                fontWeight: 100,
                fontStyle: "normal",
                color: "#0A6644",
                paddingTop: "40px",
                paddingBottom: ["100px", "200px"],
              }}
            >
              *Corresponde al día de vencimiento de cada cuota
            </Typography>
          </>
        )}
      </Grid>
      <Modal isOpen={isOpenModal}>
        <ModalContent>
          <ModalText>Póliza cuenta con cargo activo</ModalText>
          <ModalButtonContainer>
            <ModalButton
              onClick={() => {
                setIsopenModal(false);
              }}
            >
              Aceptar
            </ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>
      <Modal isOpen={error}>
        <ModalContent>
          <ModalText>Ingrese el número de DUI.</ModalText>
          <ModalButtonContainer>
            <ModalButton
              onClick={() => {
                setError(false);
              }}
            >
              Aceptar
            </ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>
      <Modal isOpen={duierror}>
        <ModalContent>
          {/* <ModalText>DUI no existe.</ModalText> */}
          <ModalText>
            DUI no existe ó no se han encontrado pólizas vigentes asociadas al
            DUI consultado.
          </ModalText>
          <ModalButtonContainer>
            <ModalButton
              onClick={() => {
                setDuiError(false);
              }}
            >
              {/* OK */}
              Aceptar
            </ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>
      <BottomBar />
    </>
  );
}

export default Cargosrecurrentesdesktop;
