import React, { useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
// import styled from "@mui/material/styles/styled";
import AddCard from "../../Assets/add_card.png";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddNewCard from "./AddNewCard";
import DisplayCardDetails from "./DisplayCardDetails";
import BottomBar from "./BottomBar";
import { useState } from "react";
import { useRef } from "react";
import {
  AddcardModal,
  AddcardModalContent,
  CloseButton,
  ModalHeadder,
  ModalTop
} from "../TableStyle/Tablestyle";
import { faLadderWater } from "@fortawesome/free-solid-svg-icons";

const MisTarjetasDesktop = () => {
  const [open, setOpen] = useState(false);
  const addNewCardRef = useRef(null);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
    if (!open && addNewCardRef.current) {
      addNewCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleclose = () => {
    setOpen(false);
    
  };
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);

  return (
    <>
      <Grid
        container
        columns={{ xs: 4, md: 12 }}
        sx={{
          padding: { xs: "10px 1rem", md: "20px 5.625rem" },
        }}
      >
        {/* title */}
        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{
              fontSize: "2.5rem",
              fontWeight: "900",
              color: "#0A6644",
              fontStyle: "normal",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            Mis tarjetas
          </Typography>
        </Grid>
        {/* description */}
        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{
              fontSize: { xs: "1.5rem", sm: "2rem" },
              fontWeight: "390px",
              color: "#0A6644",
              fontStyle: "normal",
              maxWidth: "77.835vw",
            }}
          >
            Gestiona las tarjetas disponibles para la cuenta. Puedes agregar o
            eliminar la tarjeta
          </Typography>
        </Grid>

        {/* add card title */}
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ padding: "1rem 0" }}
        >
          <Grid item>
            <IconButton
              onClick={handleClick}
              sx={{
                backgroundColor: "#0A6644",
                borderRadius: "0rem",
                "&:hover": {
                  backgroundColor: "#0A6644",
                },
              }}
            >
              <img
                src={AddCard}
                alt="Add Card"
                style={{
                  maxWidth: { xs: "5vw", md: "3.9vw" },
                  width: "auto",
                  height: "auto",
                  // padding: { xs: "1rem", md: "1rem" },
                  padding: "1.3rem 2.4rem",
                }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "1.5rem", md: "2rem" },
                fontWeight: 900,
                fontStyle: "normal",
                color: "#0A6644",
              }}
            >
              Agregar tarjeta
            </Typography>
          </Grid>
        </Grid>
        <DisplayCardDetails />
        {open && (
          <AddcardModal>
            <AddcardModalContent>
              <ModalTop>
              
              <ArrowBackIosNewIcon
                  sx={{
                    color: "#0A6644",
                    fontSize: "2.5rem",
                    marginRight: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={handleclose}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: "1.5rem", md: "2rem" },
                    fontWeight: 900,
                    fontStyle: "normal",
                    color: "#0A6644",
                    marginLeft:"0px",
                  }}
                >
                  Agregar tarjeta
                </Typography>
                {/* <CloseButton onClick={() => setOpen(false)}>cerca</CloseButton> */}
              </ModalTop>
              <AddNewCard />
            </AddcardModalContent>
          </AddcardModal>
        )}
      </Grid>
      <BottomBar />
    </>
  );
};

export default MisTarjetasDesktop;
