import React, { useState } from "react";
import { Grid, Typography, TextField, Box, Button } from "@mui/material";
import {
  Modal,
  ModalContent,
  ModalText,
  ModalButton,
  ModalButtonContainer,
} from "../TableStyle/Tablestyle";
import {
  Modal2,
  ModalContent2,
  ModalText2,
  ModalButton2,
  ModalButtonContainer2,
} from "../TableStyle/Tablestyle";
import CardNew from "../../Assets/card2.png";
import CardCVV from "../../Assets/card_cvv.png";
import RightCard from "../../Assets/right_card.png";
import { createCard, verifyClient } from "../../api/Axios";
import { AddWrapper, InnerWrapper } from "../TableStyle/Tablestyle";
import one from "../../Assets/Images/1.svg";
import two from "../../Assets/Images/2.svg";
import three from "../../Assets/Images/3.svg";
import lock from "../../Assets/Images/lock.svg";
const AddNewCard = () => {
  const [formData, setFormData] = useState({
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    cardHolderName: "",
  });
  const [errors, setErrors] = useState({});
  const [amtValue, setamtValue] = useState("");
  const [isdisable, setisdisable] = useState(true);
  const [isOpenModal, setIsopenModal] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState("");
  const [tocken, settocken] = useState("");
  const [auth, setauth] = useState("");
  const [OpenModal, setopenModal] = useState(false);
  let sucmessage = [
    `Agregar y validar tu tarjeta
  Pasos:
  1-Busca el cargo temporal hecho a tu tarjeta, puede ser enviado por SMS o por correo electrónico. Este cargo es temporal y será revertido.
  2-Introduce el cargo generado en la casilla de verificación.
  3-Presiona el botón Guardar para comprobar el monto cargado sea igual al código de seguridad. Solamente serán almacenadas tarjetas validadas.`,
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.slice(0, 30);
    const formattedValue = trimmedValue.replace(/[^a-zA-Z\s]/g, "");
    const formattedCardNumber = value
      .replace(/\D/g, "")
      .replace(/(\d{4})(?=\d)/g, "$1 ")
      .trim()
      .slice(0, 19);
    const formattedDate = value
      .replace(/\D/g, "") // Remove non-digit characters
      .replace(/(\d{2})(\d{2})/, "$1/$2") // Add a slash (/) between MM and DD
      .slice(0, 5); // Limit length to 5 characters (MM/DD)
    const formattedCVV = value.slice(0, 3);
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "cardNumber"
          ? formattedCardNumber
          : name === "expirationDate"
          ? formattedDate
          : name === "cardHolderName"
          ? formattedValue
          : formattedCVV,
    }));
  };
  const submitValidar = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const formattedDate =
        formData.expirationDate.toString().replace("/", "").substring(0, 2) +
        formData.expirationDate.slice(-2);
      const requestData = {
        merchant_id: "999999999999",
        account_no: formData.cardNumber.replace(/\s/g, ""),
        expiration_date: formattedDate,
        cvv_no: formData.cvv,
      };
      console.log("requestData:", requestData);
      createCard(requestData)
        .then((response) => {
          console.log("create card response :", response.data);
          if (response.data.success === true) {
            settocken(response.data.data[0].Data);
            setauth(response.data.data[0].Auth);
            setSuccessAlertMessage(sucmessage);
            setopenModal(true);
            setisdisable(false);
            console.log(auth);
          } else {
            setSuccessAlertMessage("Tarjeta invalida, favor verifique");
            setIsopenModal(true);
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSubmit = (e) => {
    console.log(tocken, auth);
    e.preventDefault();
    const validationErrors = validateFormAmount();
    if (Object.keys(validationErrors).length === 0) {
      const formattedDate =
        formData.expirationDate.toString().replace("/", "").substring(0, 2) +
        formData.expirationDate.slice(-2);
      const requestData = {
        merchant_id: "999999999999",
        token: tocken,
        auth: auth,
        account_no: formData.cardNumber.replace(/\s/g, ""),
        expiration_date: formattedDate,
        cvv_no: formData.cvv,
        amt: amtValue,
      };
      console.log(requestData);
      verifyClient(requestData)
        .then((response) => {
          console.log(response.data);
          if (response.data.success === true) {
            setSuccessAlertMessage("");
            setIsopenModal(true);
          } else {
            setSuccessAlertMessage(response.data.message);
            setIsopenModal(true);
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    if (formData.cardNumber.trim() === "") {
      validationErrors.cardNumber = "Se requiere número de tarjeta";
    }
    if (formData.expirationDate.trim() === "") {
      validationErrors.expirationDate = "Se requiere fecha de caducidad";
    }
    if (formData.cvv.trim() === "") {
      validationErrors.cvv = "requerida";
    }
    if (formData.cardHolderName.trim() === "") {
      validationErrors.cardHolderName =
        "Se requiere el nombre del titular de la tarjeta";
    }
    return validationErrors;
  };
  const validateFormAmount = () => {
    let validationErrors = {};
    if (formData.cardNumber.trim() === "") {
      validationErrors.cardNumber = "Se requiere número de tarjeta";
    }
    if (formData.expirationDate.trim() === "") {
      validationErrors.expirationDate = "Se requiere fecha de caducidad";
    }
    if (formData.cvv.trim() === "") {
      validationErrors.cvv = "requerida";
    }
    if (formData.cardHolderName.trim() === "") {
      validationErrors.cardHolderName =
        "Se requiere el nombre del titular de la tarjeta";
    }
    if (amtValue < 0.01 || amtValue > 1.99) {
      validationErrors.amtvalue = "El monto debe estar entre 0.01 y 1.99";
    }
    return validationErrors;
  };

  const areAllFieldsFilled = () => {
    return (
      formData.cardNumber &&
      formData.expirationDate &&
      formData.cvv &&
      formData.cardHolderName
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ border: "2px solid #0A6644" }}>
        <Grid
          container
          alignItems="center"
          spacing={4.5}
          sx={{ padding: "1rem 0rem 0rem 3rem" }}
        >
          <Grid item>
            <img src={CardNew} alt="card new" />
          </Grid>
          <Grid item>
            <Typography
              variant="p"
              sx={{
                fontSize: { xs: "1.5rem", sm: "1.75rem" },
                fontWeight: "900",
                color: "#0A6644",
                fontStyle: "normal",
              }}
            >
              Datos de tarjeta
            </Typography>
          </Grid>
        </Grid>

        {/* form fields */}
        <Grid
          sx={{
            paddingLeft: { xs: "1rem", sm: "1rem", md: "9rem" },
            width: "100%",
          }}
        >
          <AddWrapper>
            {/* card number */}
            <Grid>
              <Box
                component="form"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `2px solid ${
                        errors.cardNumber ? "red" : "#0A6644"
                      }`,
                      borderRadius: 0,
                    },
                    "&:hover fieldset": { borderColor: "#0A6644" },
                    "&.Mui-focused fieldset": { borderColor: "#0A6644" },
                  },
                  "& .MuiFormLabel-root": { color: "#0A6644" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  style={{ width: "100%" }}
                  error={!!errors.cardNumber}
                  helperText={errors.cardNumber}
                  id="cardNumber"
                  name="cardNumber"
                  label="Número de tarjeta 0000 0000 0000 0000"
                  value={formData.cardNumber}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <InnerWrapper>
              {/* expiration date*/}
              <Grid>
                <Box
                  component="form"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: `2px solid ${
                          errors.expirationDate ? "red" : "#0A6644"
                        }`,
                        borderRadius: 0,
                      },
                      "&:hover fieldset": { borderColor: "#0A6644" },
                      "&.Mui-focused fieldset": { borderColor: "#0A6644" },
                    },
                    "& .MuiFormLabel-root": { color: "#0A6644" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    style={{ width: "100%" }}
                    error={!!errors.expirationDate}
                    helperText={errors.expirationDate}
                    id="expirationDate"
                    name="expirationDate"
                    label="Fecha de expiración YY/MM"
                    value={formData.expirationDate}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>

              {/* cvv */}
              <Grid>
                <Box
                  component="form"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: `2px solid ${errors.cvv ? "red" : "#0A6644"}`,
                        borderRadius: 0,
                      },
                      "&:hover fieldset": { borderColor: "#0A6644" },
                      "&.Mui-focused fieldset": { borderColor: "#0A6644" },
                    },
                    "& .MuiFormLabel-root": { color: "#0A6644" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    style={{ width: "100%" }}
                    error={!!errors.cvv}
                    helperText={errors.cvv}
                    id="cvv"
                    name="cvv"
                    label="CVV"
                    value={formData.cvv}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>

              {/* cvv image */}
              <Grid style={{ display: "flex", justifyContent: "right" }}>
                <img
                  src={CardCVV}
                  alt="Card CVV"
                  style={{
                    maxWidth: { xs: "20vw", sm: "20vw", md: "4.44vw" },
                    width: "auto",
                    height: "auto",
                  }}
                />
              </Grid>
            </InnerWrapper>

            {/* card holder name */}
            <Grid>
              <Box
                component="form"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `2px solid ${
                        errors.cardHolderName ? "red" : "#0A6644"
                      }`,
                      borderRadius: 0,
                    },
                    "&:hover fieldset": { borderColor: "#0A6644" },
                    "&.Mui-focused fieldset": { borderColor: "#0A6644" },
                  },
                  "& .MuiFormLabel-root": { color: "#0A6644" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  style={{ width: "100%" }}
                  error={!!errors.cardHolderName}
                  helperText={errors.cardHolderName}
                  id="cardHolderName"
                  name="cardHolderName"
                  label="Nombre del titular"
                  value={formData.cardHolderName}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </AddWrapper>
          <br></br>
          <Grid
            style={{
              width: "90%",
              display: "grid",
              gridTemplateColumns: "9fr 1fr",
            }}
          >
            <Grid></Grid>
            <Grid>
              {" "}
              <Button
                onClick={submitValidar}
                variant="contained"
                sx={{
                  backgroundColor: "#0A6644",
                  color: "#FFFFFF",
                  width: "13rem",
                  height: "3.5rem",
                  fontSize: { xs: "1.5rem", sm: "1.5rem", md: "2rem" },
                  fontWeight: "390px",
                  borderRadius: "0.9rem",
                  textTransform: "none",
                  fontStyle: "normal",
                  "&:hover": { backgroundColor: "#0A6644" },
                }}
                disabled={!areAllFieldsFilled()}
              >
                <span style={{ marginRight: "0.5rem" }}>Validar</span>
                <img src={RightCard} alt="right card" />
              </Button>
            </Grid>
          </Grid>
          <br></br>
          <Grid
            style={{
              width: "90%",
              display: "grid",
              gridTemplateColumns: "9fr 1fr",
            }}
          >
            <Grid>
              <Typography
                variant="p"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "390px",
                  color: "#0A6644",
                }}
              >
                Ingresa el cargo realizado a tu tarjeta
              </Typography>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: "right" }}>
              <Box
                component="form"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `2px solid ${errors.cvv ? "red" : "#0A6644"}`,
                      borderRadius: 0,
                    },
                    "&:hover fieldset": { borderColor: "#0A6644" },
                    "&.Mui-focused fieldset": { borderColor: "#0A6644" },
                  },
                  "& .MuiFormLabel-root": { color: "#0A6644" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  style={{ width: "130%" }}
                  // error={!!errors.amtvalue}
                  // helperText={errors.amtvalue}
                  id="amt"
                  name="amo"
                  placeholder="amout"
                  disabled={isdisable}
                  onChange={(e) => setamtValue(e.target.value)}
                />
              </Box>
            </Grid>
            <p style={{ color: "red" }}>{errors.amtvalue}</p>
          </Grid>
          <br></br>
          <Grid
            style={{
              width: "90%",
              display: "grid",
              gridTemplateColumns: "9fr 1fr",
            }}
          >
            <Grid></Grid>
            <Grid>
              {" "}
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#0A6644",
                  color: "#FFFFFF",
                  width: "13rem",
                  height: "3.5rem",
                  fontSize: { xs: "1.5rem", sm: "1.5rem", md: "2rem" },
                  fontWeight: "390px",
                  borderRadius: "0.9rem",
                  textTransform: "none",
                  fontStyle: "normal",
                  "&:hover": { backgroundColor: "#0A6644" },
                }}
                disabled={isdisable}
              >
                <span style={{ marginRight: "0.5rem" }}>Guardar</span>
                <img src={RightCard} alt="right card" />
              </Button>
            </Grid>
          </Grid>
          <br></br>
        </Grid>
      </Box>
      <Modal isOpen={isOpenModal}>
        <ModalContent>
          <ModalText>{successAlertMessage}</ModalText>
          <ModalButtonContainer>
            <ModalButton
              onClick={() => {
                setIsopenModal(false);
                //window.location.reload();
              }}
            >
              Aceptar
            </ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>
      <Modal2 isOpen={OpenModal}>
        <ModalContent2>
          <ModalText2>
            <div>
              <img
                src={lock}
                alt="lock"
                style={{
                  width: "9%",
                  height: "auto",
                  color: "green",
                  fill: "green",
                }}
              />
            </div>

            <div> Agregar y validar tu tarjeta</div>
            <div>
              <p style={{ textAlign: "left" }}>Pasos:</p>
            </div>
            <table style={{ textAlign: "center" }}>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <img
                    src={one}
                    alt="lock"
                    style={{ height: "auto", marginRight: "10px" }}
                  />
                </td>
                <td style={{ verticalAlign: "top", textAlign: "justify" }}>
                  Busca el cargo temporal hecho a tu tarjeta, puede ser enviado
                  por SMS o por correo electrónico. Este cargo es temporal y
                  será revertido.
                </td>
              </tr>
              <br></br>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <img
                    src={two}
                    alt="lock"
                    style={{ height: "auto", marginRight: "10px" }}
                  />
                </td>
                <td style={{ verticalAlign: "top", textAlign: "justify" }}>
                  Introduce el cargo generado en la casilla de verificación.
                </td>
              </tr>
              <br></br>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <img
                    src={three}
                    alt="lock"
                    style={{ height: "auto", marginRight: "10px" }}
                  />
                </td>
                <td style={{ verticalAlign: "top", textAlign: "justify" }}>
                  {/* Presiona el botón Guardar para comprobar el monto cargado sea
                  igual al código de seguridad. Solamente serán almacenadas
                  tarjetas validadas. */}
                  Presiona el botón Guardar para comprobar que el monto cargado sea correcto. Solamente serán almacenadas tarjetas validadas.
                </td>
              </tr>
            </table>
          </ModalText2>
          <ModalButtonContainer2>
            <ModalButton2
              onClick={() => {
                setopenModal(false);
                //window.location.reload();
              }}
            >
              Aceptar
            </ModalButton2>
          </ModalButtonContainer2>
        </ModalContent2>
      </Modal2>
    </form>
  );
};

export default AddNewCard;
