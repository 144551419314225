import React, { useEffect } from "react";
import MisTarjetasDesktop from "../../Component/MisTarjetas";
import Layout from "../../Component/Layout/Layout";

const MisTarjetas = () => {
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  return (
    <Layout>
      <MisTarjetasDesktop />
      <br />
      <br />
    </Layout>
  );
};

export default MisTarjetas;
