import React, { useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";

function OTP({ onSubmit }) {
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);

  const handleOtpDigitChange = (index, value) => {
    const updatedOtpDigits = [...otpDigits];
    updatedOtpDigits[index] = value;
    setOtpDigits(updatedOtpDigits);
  };

  const handleSubmit = () => {
    const otpCode = otpDigits.join("");
    onSubmit(otpCode);
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={2}
      sx={{ padding: "2rem" }}
    >
      <Typography variant="p" style={{fontSize:'30px'}}>Enter OTP Code</Typography>
      <Box display="flex" justifyContent="center">
        {otpDigits.map((digit, index) => (
          <input
            key={index}
            value={digit}
            onChange={(e) => handleOtpDigitChange(index, e.target.value)}
            style={{
              width: "3rem",
              height: "3rem",
              fontSize: "1.5rem",
              textAlign: "center",
              margin: "0.5rem",
            }}
          />
        ))}
      </Box>
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={otpDigits.some((digit) => digit === "")}
      >
        Submit
      </Button>
    </Grid>
  );
}

export default OTP;
