import React, { useEffect, useState } from "react";
import Logo from "../../Const/Logo";
import styled from "@emotion/styled";
import {
  TextField,
  Button,
  Typography,
  Grid,
  InputAdornment,
} from "@mui/material";
import emailIcon from "../../Assets/LoginPage/email.png";
import passwordIcon from "../../Assets/LoginPage/password.png";
import loginIcon from "../../Assets/LoginPage/login.png";
import resetIcon from "../../Assets/LoginPage/reset.png";
import editIcon from "../../Assets/LoginPage/edit.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoginApi } from "../../api/Axios";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    localStorage.clear();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //   error handling
  const [emailError, setEmailError] = useState(false);
  // handle email, password change
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setEmailError(!emailRegex.test(enteredEmail));
  };

  const handlePasswordChange = (e) => {
    const enteredPassword = e.target.value;
    setPassword(enteredPassword);
    setPasswordError(enteredPassword.length < 6);
  };

  const handleSubmit = async (e) => {
    localStorage.clear();
    e.preventDefault();

    // Perform client-side validations
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(
        "Por favor, introduce una dirección de correo electrónico válida."
      );
      return;
    }
    try {
      const data = {
        email: email,
        password: password,
        login_status: "emailType",
      };
      const res = await LoginApi(data);
      // Check the response status
      if (res.data.success === true) {
        // alert("login success");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_id", res.data.sub.id);
        localStorage.setItem("user_name", res.data.sub.nombre_completo);
        window.location.href = `/menu`;
        //window.location.href = `http://ec2-18-217-109-137.us-east-2.compute.amazonaws.com:8000/menu`;
      } else {
        // Authentication failed
        const errorData = await res.message;
        setError(errorData || "Autenticación fallida");
      }
    } catch (error) {
      // Handle any network or server errors
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <LoginContainer>
      <LoginFormContainer>
        <form>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={GridContainerStyles}
          >
            {/* ------------------ Form header-------------- */}
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" sx={FormHeaderStyle}>
                Iniciar sesión
              </Typography>
            </Grid>
            {/* -------------------Form content--------------*/}
            <Grid item xs={12}>
              <TextField
                sx={TextfieldStyles}
                variant="outlined"
                margin="normal"
                required
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                placeholder="Correo electrónico"
                value={email}
                error={emailError}
                helperText={
                  emailError && "Ingrese un correo electrónico válido"
                }
                onChange={handleEmailChange}
                FormHelperTextProps={{
                  sx: {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
                InputProps={{
                  style: {
                    color: "#0A6644",
                    fontFamily: "Brandon Grotesque",
                  },
                  startAdornment: (
                    <img
                      src={emailIcon}
                      style={{ height: "98%" }}
                      alt="Email"
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={TextfieldStyles}
                variant="outlined"
                margin="normal"
                required
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                placeholder="Contraseña"
                value={password}
                error={passwordError}
                helperText={
                  passwordError &&
                  "La contraseña debe tener al menos 6 caracteres"
                }
                onChange={handlePasswordChange}
                FormHelperTextProps={{
                  sx: {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
                InputProps={{
                  style: {
                    color: "#0A6644",
                    fontFamily: "Brandon Grotesque",
                  },
                  startAdornment: (
                    <img
                      src={passwordIcon}
                      style={{ height: "98%" }}
                      alt="Password"
                    />
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <VisibilityIcon
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {error && (
              <div className="text-danger" style={{ color: "red" }}>
                {error}
              </div>
            )}

            {/* ---------------- login, forgot password buttons section ---------------- */}
            <Grid item xs={12} sx={secondaryGridStyle}>
              <Typography sx={secondaryGridTextStyles}>Ingresar</Typography>
              <Button sx={secondaryGridbuttonStyles} onClick={handleSubmit}>
                <img src={loginIcon} alt="login" width={40} />
              </Button>
            </Grid>
            <Grid item xs={12} sx={secondaryGridStyle}>
              <Typography sx={secondaryGridTextStyles}>
                Ha olvidado su contraseña
              </Typography>
              <Button
                sx={secondaryGridbuttonStyles}
                onClick={() => {
                  window.location.href = "/forgetpassword"; // Replace with your actual URL
                }}
              >
                <img src={resetIcon} alt="login" width={40} />
              </Button>
            </Grid>
            {/* ---------------button section ---------------- */}
            <Grid item xs={12}>
              <ButtonContainer>
                <Typography sx={topButtonTextStyle}>
                  No tienes una cuenta?
                </Typography>

                <Button
                  sx={buttonStyle}
                  variant="contained"
                  endIcon={<CustomImage />}
                  style={{ textTransform: "none" }}
                  onClick={() => {
                    window.location.href = "/register";
                  }}
                >
                  Crear cuenta
                </Button>
                <a
                  //  href="/privacy-policy"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography sx={bottomButtonTextStyle}>
                    Política de privacidad
                  </Typography>
                </a>
              </ButtonContainer>
            </Grid>
          </Grid>
        </form>
      </LoginFormContainer>
    </LoginContainer>
  );
}

const LoginContainer = styled.div``;
const LoginFormContainer = styled.div`
  width: 44vw;
  height: 70vh;
  background-color: white;
  margin: auto;
  border: 3px solid #aecb35;
  border-radius: 20px;
  margin-top: 2vw;
  @media (max-width: 450px) {
    height: 60vh;
  }
  @media (max-width: 600px) {
    width: 80vw;
    height: 75vh;
  }
  @media (max-width: 1500px) {
    height: 70vh;
  }
`;
const GridContainerStyles = {
  width: { md: "29.1vw", sm: "38.1vw", xs: "48.1vw" },
  margin: "auto",
  "& .MuiGrid-item": {
    paddingLeft: "0px",
    paddingTop: "0px",
  },
};
const FormHeaderStyle = {
  textAlign: "left",
  color: "#0A6644",
  fontWeight: 650,
  fontSize: "1.5rem",
  fontFamily: "Brandon Grotesque",
  paddingTop: "1.5vw",
  marginBottom: "1vw",
};
const TextfieldStyles = {
  "& .MuiFormControl-root": {
    "& .MuiTextField-root": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#0A6644",
    opacity: 1, // Set opacity to 1 to ensure the color is fully visible
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #0A6644",
    backgroundColor: "transparent",
    padding: "0 1px",
    top: "-4px",
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiInputBase-root ": {
    paddingLeft: "0px",
    width: { md: "29vw", sm: "38vw", xs: "48vw" },
    height: { md: "7.5vh", sm: "6vh", xs: "5vh" },
  },
  "& .MuiInputBase-input": {
    paddingLeft: "14px",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "3px solid #0A6644",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "3px solid #0A6644",
    },
  },
  "& fieldset": {
    borderColor: "#0A6644", // Set the border color for the default state
    legend: {
      width: "0px",
    },
  },
  "&:hover fieldset": {
    borderColor: "#0A6644", // Set the border color for the hover state
  },
};

const secondaryGridStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "right",
};

const secondaryGridTextStyles = {
  color: "#0A6644",
  fontSize: "1rem",
  fontFamily: "Brandon Grotesque",
  marginRight: "0.5vw",
};
const secondaryGridbuttonStyles = {
  minWidth: "40px",
  margin: "0px",
  padding: "0px",
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 20vw;
  margin: auto;
  padding-top: 3vw;
  @media (max-width: 1000px) {
    width: 22vw;
  }
  @media (max-width: 900px) {
    width: 30vw;
  }
  @media (max-width: 600px) {
    width: 40vw;
  }
`;

const topButtonTextStyle = {
  color: "#0A6644",
  fontSize: "0.9rem",
  fontFamily: "Brandon Grotesque",
};
const bottomButtonTextStyle = {
  color: "#0A6644",
  fontSize: "0.8rem",
  fontWeight: "600",
  fontFamily: "Brandon Grotesque",
};

const buttonStyle = {
  backgroundColor: "#0A6644",
  width: { md: "20vw", sm: "30vw", xs: "40vw" },
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "0px",
  fontFamily: "Brandon Grotesque",
  fontSize: "1.2rem",
  "&:hover": {
    backgroundColor: "#0A6644",
  },
  margin: "0.3vw 0",
};

const CustomImage = () => (
  <img src={editIcon} alt="edit" width={30} style={{ marginBottom: "0.5vw" }} />
);
