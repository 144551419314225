import React from "react";
import logoImage from "../../Assets/Images/Logo.svg";
import { Link } from "react-router-dom";
import './layout.css'

const Layout = ({ children }) => {
  return (
    <div className="container">
      <div className="logo-container">
        <Link to="/menu">
          <img src={logoImage} alt="Logo" />
        </Link>
      </div>
      <div>{children}</div>
      <div style={{height:'100px'}}></div>
    </div>
  );
};

export default Layout;
