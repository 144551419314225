import React from "react";
import LayoutLogin from "../../Component/Layout/LayoutLogin";
import styled from "styled-components";
import Forget from "../../Component/Forget/Forget";

function ForgetPassword() {
  return (
    <LayoutLogin>
      <RegisterFormWrapper>
        <Forget />
      </RegisterFormWrapper>
    </LayoutLogin>
  );
}

export default ForgetPassword;

const RegisterFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
`;
