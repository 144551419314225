import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import BrandonGrotesqueRegular from "./Assets/Fonts/BrandonGrotesque-Regular.woff";
import BrandonGrotesqueBold from "./Assets/Fonts/BrandonGrotesque-Bold.woff";
const fontStyles = `
  @font-face {
    font-family: 'Brandon Grotesque';
    src: url(${BrandonGrotesqueRegular}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brandon Grotesque';
    src: url(${BrandonGrotesqueBold}) format('woff');
    font-weight: bold;
    font-style: normal;
  }
`;

const style = document.createElement('style');
style.innerHTML = fontStyles;
document.head.appendChild(style);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();