import React from "react";
import styled from "styled-components";
import LayoutLogin from "../../Component/Layout/LayoutLogin";
import Reset from "../../Component/Reset/Reset";

function ResetPassword() {
  return (
    <div>
      <LayoutLogin>
        <RegisterFormWrapper>
          <Reset />
        </RegisterFormWrapper>
      </LayoutLogin>
    </div>
  );
}

export default ResetPassword;
const RegisterFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
`;
