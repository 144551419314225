import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import {
  TableBodyCell,
  TableComponent,
  TableBody,
  TableHeaderCell,
  TableBodyActive,
  ActiveUpperContainer,
  ActiveDownContainer,
  AcctiveRow,
  AcctiveColumn,
  TableHeaderActive,
  TableHeaderModification,
  RowMod,
  Modal,
  ModalContent,
  ModalText,
  ModalButtonContainer,
  ModalButton,
} from "../TableStyle/Tablestyle";
import BottomBar from "../MisTarjetas/BottomBar";
import TicIcon from "../../Assets/ticicon.png";
import { createautopayment, getCardById } from "../../api/Axios";
import { useParams } from "react-router-dom";

function Activarcargosrecurrentesdesktop() {
  const {
    asecode,
    poliza,
    ramo,
    monto,
    vence,
    nombre,
    idProcessPayment,
    rowIndex,
    dui,
    cuotaData,
  } = useParams();
  const [date_, setDate_] = useState("");
  const [carddata, setCardData] = useState([]);
  const [token, setToken] = useState("");
  const [isOpenModal, setIsopenModal] = useState(false);
  const [datevalidation, setDatevalidation] = useState(false);
  const [cardvalidation, setCardvalidation] = useState(false);
  const [cvvvalidation, setCvvvalidation] = useState(false);
  const [cvv, setCvv] = useState("");
  const [cvvValied, setCvvValied] = useState(false);
  const myArray = JSON.parse(cuotaData);
  const userid = localStorage.getItem("user_id");
  const [selectedCardNumber, setSelectedCardNumber] = useState("");
  let maskedAccountNo = "";

  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  useEffect(() => {
    getCardById(userid)
      .then((res) => {
        //console.log(res.data.data);
        setCardData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userid]);
  // console.log(idProcessPayment);
  const handleSubmit = async () => {
    var issubmit = false;
    if (!date_) {
      setDatevalidation(true);
    }
    if (!token) {
      setCardvalidation(true);
    }
    if (!cvvValied) {
      setCvvvalidation(true);
    }
    if (date_ && token && cvvValied) {
      issubmit = true;
    }

    if (issubmit) {
      const vPaymentPara = {
        FeesToPay: [
          {
            IdPolEndo: myArray[0],
            IdPol: myArray[1],
            IdPag: myArray[2],
            NoCuota: myArray[3],
          },
        ],
      };
      //  console.log(myArray[0]);
      //  const vPaymentPara = `{"FeesToPay": [{\\"IdPolEndo\\":\\"${cuotaData[0]}\\",\\"IdPol\\":\\"${cuotaData[1]}\\",\\"IdPag\\":\\"${cuotaData[2]}\\",\\"NoCuota\\":\\"${cuotaData[3]}\\"}]}`;
      // Serialize the JavaScript object to a JSON string
      const vPaymentParaJSON = JSON.stringify(vPaymentPara);
      try {
        const value = {
          merchant_id: "999999999999",
          token: token,
          amount: monto,
          additional: "",
          date: date_,
          policy_no: poliza,
          ramo: ramo,
          nombre: nombre,
          IdProcessPayment: idProcessPayment,
          rowNumber: rowIndex,
          DUINumber: dui,
          vPaymentPara: vPaymentParaJSON,
          cardDetails: selectedCardNumber,
        };
        console.log(value);
        const res = await createautopayment(value);
        const success = res.data.success;
        if (success === "true") {
          setIsopenModal(true);
        } else {
          setIsopenModal(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("Something wrong in data entering");
    }
  };

  const areAllFieldsFilled = () => {
    return date_ && token && cvv;
  };

  return (
    <>
      <Grid
        container
        columns={{ xs: 4, md: 12 }}
        sx={{
          padding: { xs: "10px 1rem", md: "20px 5.625rem" },
        }}
      >
        {/* title */}
        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{
              fontSize: ["2rem", "2.5rem"],
              fontWeight: "900",
              color: "#0A6644",
              fontStyle: "normal",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            Activar cargos recurrentes
          </Typography>
        </Grid>
        {/* description */}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: ["1.5rem", "2rem"],
              fontWeight: "390px",
              color: "#0A6644",
              fontStyle: "normal",
              maxWidth: "77.835vw",
            }}
          >
            Pulse sobre la póliza para activar el cargo recurrente
          </Typography>
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <TableComponent style={{ paddingTop: "20px" }}>
            <TableHeaderModification>
              <TableHeaderCell>Póliza </TableHeaderCell>
              <TableHeaderCell>Ramo</TableHeaderCell>
              <TableHeaderCell>Monto</TableHeaderCell>
              <TableHeaderCell> Vence*</TableHeaderCell>
              <TableHeaderCell> Asegurado</TableHeaderCell>
            </TableHeaderModification>
            <TableBody>
              <RowMod>
                <TableBodyCell>{poliza}</TableBodyCell>
                <TableBodyCell>{ramo}</TableBodyCell>
                <TableBodyCell>${monto}</TableBodyCell>
                <TableBodyCell>{vence}</TableBodyCell>
                <TableBodyCell>{nombre}</TableBodyCell>
              </RowMod>
            </TableBody>
          </TableComponent>
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <Typography
            variant="p"
            sx={{
              fontSize: ["1rem", "1.5rem"],
              fontWeight: 100,
              fontStyle: "normal",
              color: "#0A6644",
              paddingTop: "20px",
            }}
          >
            *Corresponde al día de vencimiento de cada cuota
            <br></br>
            {/* Si un mes no cuenta con el día seleccionado, el cargo se realizará
            el día inmediato anterior. */}
          </Typography>
        </Grid>
        <Box sx={{ height: "20px" }} />
        <br></br>
        <Grid sx={{ width: ["100%", "75%"] }}>
          <TableComponent style={{ padding: "10px 0px" }}>
            <TableHeaderActive>
              <TableHeaderCell>Activar cargo </TableHeaderCell>
            </TableHeaderActive>
            <TableBodyActive>
              <ActiveUpperContainer>
                <label>Día de cargo</label>
                <div>
                  <select
                    value={date_}
                    onChange={(e) => {
                      const newDate = e.target.value;
                      setDate_(newDate);
                      if (newDate !== "") {
                        setDatevalidation(false);
                      }
                    }}
                  >
                    <option value="" disabled></option>
                    {Array.from({ length: 31 }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>

                  {datevalidation && (
                    <p style={{ color: "red", fontSize: "16px", margin: "0" }}>
                      día de carga requerido
                    </p>
                  )}
                </div>
                <label>Monto a cargar</label>
                <input type="number" value={monto} disabled />
              </ActiveUpperContainer>
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: ["1rem", "1rem"],
                    fontWeight: "100",
                    color: "#0A6644",
                    fontStyle: "normal",
                    paddingLeft: "50px",
                  }}
                >
                  ** Estimado cliente, el cargo será procesado a las 9:00 pm del
                  día seleccionado.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: ["1.5rem", "2rem"],
                    fontWeight: "900",
                    color: "#0A6644",
                    fontStyle: "normal",
                    padding: "50px",
                  }}
                >
                  Mis tarjetas:
                </Typography>
              </Grid>
              <ActiveDownContainer>
                {carddata.map((data) => {
                  const cvvNo = data.cvv_no;
                  const last4Digits = data.account_no.slice(-4);
                  maskedAccountNo =
                    "*".repeat(data.account_no.length - 4) + last4Digits;

                  const handleRadioChange = (e) => {
                    const token = e.target.value;
                    setToken(token);

                    // Find the selected card number from carddata using the token
                    const selectedCard = carddata.find(
                      (card) => card.token === token
                    );

                    if (selectedCard) {
                      const selectedCardNumber = selectedCard.account_no;
                      setSelectedCardNumber(selectedCardNumber);
                    } else {
                      setSelectedCardNumber("");
                    }

                    if (token !== "") {
                      setCardvalidation(false);
                    }
                  };

                  return (
                    <AcctiveRow key={data.token}>
                      <AcctiveColumn>
                        <input
                          style={{ backgroundColor: "rgba(10, 102, 68, 1)" }}
                          type="radio"
                          name="tic"
                          value={data.token}
                          onChange={handleRadioChange}
                        />
                      </AcctiveColumn>
                      <AcctiveColumn>
                        {maskedAccountNo} <br />
                        Expira {data.expiration_date.substring(0, 2)}/
                        {data.expiration_date.substring(2)}
                      </AcctiveColumn>
                      <AcctiveColumn>
                        {data.token === token && (
                          <>
                            <label style={{ paddingLeft: "20px" }}>CVV</label>
                            <br />
                            <span>
                              <input
                                type="text"
                                name="cvv"
                                maxLength={3}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  setCvv(inputValue);
                                  const formattedCVV = inputValue.slice(0, 3);
                                  setCvvvalidation(false);
                                  if (formattedCVV === cvvNo) {
                                    setCvvValied(true);
                                  } else {
                                    setCvvValied(false);
                                  }
                                }}
                              />
                            </span>
                            {cvvvalidation && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                              >
                                CVV no válido
                              </p>
                            )}
                          </>
                        )}
                      </AcctiveColumn>
                    </AcctiveRow>
                  );
                })}

                {cardvalidation && (
                  <p style={{ color: "red", fontSize: "16px", margin: "0" }}>
                    tarjeta requerida
                  </p>
                )}
              </ActiveDownContainer>
            </TableBodyActive>
          </TableComponent>
          <Typography
                  variant="p"
                  sx={{
                    fontSize: ["1rem", "1rem"],
                    fontWeight: "100",
                    color: "#0A6644",
                    fontStyle: "normal",
                  }}
                >
                 Si un mes no cuenta con el día seleccionado, el cargo se realizará el día inmediato anterior.
                </Typography>

        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem 0rem",
          }}
        >
          <Button
            disabled={!areAllFieldsFilled()}
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            sx={{
              top: ["0px", "0px", "-80px"],
              backgroundColor: "#0A6644",
              color: "#FFFFFF",
              width: "12rem",
              height: "3.5rem",
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
              fontWeight: "390px",
              borderRadius: "0.9rem",
              textTransform: "none",
              fontStyle: "normal",
              "&:hover": { backgroundColor: "#0A6644" },
            }}
          >
            <span variant="p" style={{ marginRight: "0.5rem" }}>
              Guardar
            </span>
            <img src={TicIcon} alt="right card" />
          </Button>
        </Grid>
      </Grid>
      <Modal isOpen={isOpenModal}>
        <ModalContent>
          <ModalText>Su cargo se ha habilitado con éxito.</ModalText>
          <ModalButtonContainer>
            <ModalButton
              onClick={() => {
                setIsopenModal(false);
                window.location.href = `/cargosrecurrentes`;
              }}
            >
              Aceptar
            </ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>
      <BottomBar />
    </>
  );
}

export default Activarcargosrecurrentesdesktop;
