import React, { useEffect } from "react";
import { Container, Grid, Typography, } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

export default function PieDePaginaPage() {
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  return (
    <div>
      {/* Empty Top Section */}
      <div style={{ height: "65vh" }}></div>

      {/* Container with Row of Icons */}
      <Container sx={containerStyle}>
        <Grid container spacing={2} sx={GridContainerStyles}>
          <Grid item sx={{ display: "flex" }}>
            <LanguageOutlinedIcon sx={iconStyles} />
          </Grid>
          <Grid item>
            <Typography sx={textStyles}> www.segurosfedecredito.com</Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <EmailOutlinedIcon sx={iconStyles} />
          </Grid>
          <Grid item>
            <Typography sx={textStyles}>
              helpdesk@segurosfedecredito.com
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const containerStyle = {
  background: "#0A6644",
  height: {md:"100px", xs:"70px"},
  width: {xl:"50%", lg:"60%",md:"60%", sm:"80%", xs:"94%"},
  border: "3px solid #AECB35",
  borderRadius: "20px",
  // marginBottom: "5vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding:{xs:"0px"}
};

const iconStyles = {
  fontSize: {md:"2rem", sm:"1.5rem", xs:"1.2rem"},
  color: "white",
  marginLeft:"2vw"
};
const textStyles = {
  color: "white",
  fontFamily: "Brandon Grotesque",
  fontSize: {md:"1rem", sm:"0.9rem", xs:"0.7rem"},
  fontWeight:"330",
  fontStyle:"normal"
};

const GridContainerStyles = {
    justifyContent: "center",
    alignItems: "center",
    padding:"0px",
    "& .MuiGrid-item": {
      paddingLeft: "0px",
    },
  };