import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Modal,
  ModalContent,
  ModalText,
  ModalButton,
  ModalButtonContainerDelete,
  ModalButtonContainer,
} from "../TableStyle/Tablestyle";
import {
  TableBodyCell,
  TableComponent,
  TableBody,
  TableHeaderCell,
  NewTableHeader,
  NewRow,
} from "../TableStyle/Tablestyle";
import BottomBar from "../MisTarjetas/BottomBar";
import { getAutoPaymentById, deleteAutoPaymentById } from "../../api/Axios";

function Desactivarcargo() {
  const [autoPaymentList, setAutoPaymentList] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletingAutoPaymentId, setDeletingAutoPaymentId] = useState(null);
  const [deleteAutoErrorAlertMessage, setDeleteAutoErrorAlertMessage] =
    useState("");
  const [isOpenModal, setIsopenModal] = useState(false);
  const userId = localStorage.getItem("user_id");
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  useEffect(() => {
    getAutoPaymentById(userId)
      .then((res) => {
        setAutoPaymentList(res.data.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  const handleDeleteAutoPayment = (autoPaymentId) => {
    setShowConfirmation(true);
    setDeletingAutoPaymentId(autoPaymentId);
  };

  const handleConfirmDeleteAutoPayment = () => {
    if (deletingAutoPaymentId) {
      const selectedAutoPayment = autoPaymentList.find(
        (autoPayment) => autoPayment.policy_no === deletingAutoPaymentId
      );

      if (selectedAutoPayment) {
        deleteAutoPaymentById(deletingAutoPaymentId)
          .then((response) => {
            if (response.data.success === true) {
              setDeleteAutoErrorAlertMessage(
                "Cargo recurrente deshabilitado con éxito."
              );
              setIsopenModal(true);
              getAutoPaymentById(userId).then((res) => {
                setAutoPaymentList(res.data.data);
                if (res.data.success === true) {
                  console.log("deleted successfully");
                } else {
                  console.log(res.data.message);
                }
              });
            } else {
              setDeleteAutoErrorAlertMessage(response.data.message);
              setIsopenModal(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    setShowConfirmation(false);
    setDeletingAutoPaymentId(null);
  };

  const handleCancelDeleteAutoPayment = () => {
    setShowConfirmation(false);
    setDeletingAutoPaymentId(null);
  };

  return (
    <div>
      <Grid
        container
        columns={{ xs: 4, md: 12 }}
        sx={{
          padding: { xs: "10px 1rem", md: "20px 5.625rem" },
        }}
      >
        {/* title */}
        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{
              fontSize: ["2rem", "2.5rem"],
              fontWeight: "900",
              color: "#0A6644",
              fontStyle: "normal",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            Desactivar cargos recurrentes
          </Typography>
        </Grid>
        {/* description */}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: ["1.5rem", "2rem"],
              fontWeight: "390px",
              color: "#0A6644",
              fontStyle: "normal",
              maxWidth: "77.835vw",
            }}
          >
            Pulsa sobre la póliza para desactivar el cargo recurrente
          </Typography>
          {/* <Typography
           variant="p"
           sx={{
             fontSize: ["1rem", "1.3rem"],
             fontWeight: "200",
             color: "#0A6644",
             fontStyle: "normal",
             justifyContent: "center",
             alignItems: "center",
             display: "flex",
           }}
          >
          <lable style={{paddingTop: "20px"}}>Asegurado : {autoPaymentList.length > 0 ? (
            autoPaymentList[0].nombre
          ) : (
            "No hay pagos recurrentes"
          )}</lable>  
        
          </Typography> */}
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <TableComponent>
            <NewTableHeader>
              <TableHeaderCell>Póliza</TableHeaderCell>
              <TableHeaderCell>Ramo</TableHeaderCell>
              <TableHeaderCell>Monto</TableHeaderCell>
              <TableHeaderCell>Día cargo</TableHeaderCell>
              <TableHeaderCell>Nombre del asegurado</TableHeaderCell>
              <TableHeaderCell>No. tarjeta</TableHeaderCell>
              <TableHeaderCell>Acción</TableHeaderCell>
            </NewTableHeader>

            {autoPaymentList.length > 0 ? (
              autoPaymentList.map((autoPayment) => (
                <TableBody>
                  <NewRow
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#f5f5f5",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                    key={autoPayment.policy_no}
                    onClick={() =>
                      handleDeleteAutoPayment(autoPayment.policy_no)
                    }
                  >
                    <TableBodyCell
                      style={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "unset",
                      }}
                    >
                      {autoPayment.policy_no}
                    </TableBodyCell>
                    <TableBodyCell
                      style={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "unset",
                      }}
                    >
                      {autoPayment.ramo}
                    </TableBodyCell>
                    <TableBodyCell
                      style={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "unset",
                      }}
                    >
                      ${autoPayment.amount}
                    </TableBodyCell>
                    <TableBodyCell
                      style={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "unset",
                      }}
                    >
                      {autoPayment.date}
                    </TableBodyCell>
                    <TableBodyCell
                      style={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "unset",
                      }}
                    >
                      {autoPayment.nombre}
                    </TableBodyCell>
                    <TableBodyCell
                      style={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "unset",
                      }}
                    >
                      {autoPayment.cardDetails}
                    </TableBodyCell>
                    <TableBodyCell
                      style={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "unset",
                      }}
                      onClick={() =>
                        handleDeleteAutoPayment(autoPayment.policy_no)
                      }
                    >
                      <DeleteIcon style={{ color: "green" }} />
                    </TableBodyCell>
                  </NewRow>
                </TableBody>
              ))
            ) : (
              <h1 style={{ textAlign: "center" }}>
                No hay datos de pago automático disponibles.
              </h1>
            )}

            {/* </TableBody> */}
          </TableComponent>
        </Grid>
        {/* Confirmation Modal */}
        <Modal isOpen={showConfirmation}>
          <ModalContent>
            <ModalText>
            ¿Estás seguro que deseas desactivar el cargo recurrente?
            </ModalText>
            <ModalButtonContainerDelete>
              <ModalButton
                style={{ borderRight: "1px solid #0a6644" }}
                onClick={handleConfirmDeleteAutoPayment}
              >
                Si
              </ModalButton>
              <ModalButton onClick={handleCancelDeleteAutoPayment}>
                No
              </ModalButton>
            </ModalButtonContainerDelete>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpenModal}>
          <ModalContent>
            <ModalText>{deleteAutoErrorAlertMessage}</ModalText>
            <ModalButtonContainer>
              <ModalButton
                onClick={() => {
                  setIsopenModal(false);
                }}
              >
                Aceptar
              </ModalButton>
            </ModalButtonContainer>
          </ModalContent>
        </Modal>
      </Grid>
      <BottomBar />
    </div>
  );
}

export default Desactivarcargo;
