import React from "react";
import styled from "styled-components";
import WebLogo from "../Assets/weblogo.png";

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoImage = styled.img`
`;

export default function Logo() {
  return (
    <LogoContainer>
      <LogoImage 
      src={WebLogo} 
      alt="Logo"
      width="350vw"
      height="auto"
       />
    </LogoContainer>
  );
}
