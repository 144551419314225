import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Typography, Grid } from "@mui/material";
import MenuIcon1 from "../../Assets/Menu/menu1.png";
import MenuIcon2 from "../../Assets/Menu/menu2.png";
import MenuIcon3 from "../../Assets/Menu/menu3.png";
import MenuIcon4 from "../../Assets/Menu/menu4.png";
import { getCardById } from "../../api/Axios";

export default function Register() {
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const userId = localStorage.getItem("user_id");
  console.log(userId);
  // Simulate fetching data from an API
  const fetchData = async () => {
    try {
      const response = await getCardById(userId);
      console.log(response.data.success);
      if (response.data.success === true) {
        setIsDataAvailable(true);
      }
    } catch (error) {
      console.log(error);
      // Handle error if needed
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <RegisterFormWrapper>
      <form>
        <Grid
          container
          spacing={2}
          sx={{
            margin: "auto",
            "& .MuiGrid-item": {
              paddingLeft: "0px",
              paddingTop: "0px",
            },
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              sx={{
                color: "#0A6644",
                fontSize: "1.5rem",
                fontWeight: 650,
                marginTop: "1vw",
                marginBottom: "1.5rem",
                fontFamily: "Brandon Grotesque",
              }}
            >
              <b>Pagos recurrentes</b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={ButtonfieldStyles}
              onClick={() => {
                window.location.href = "/mis-tarjetas";
              }}
            >
              <div
                style={{
                  backgroundColor: "#0A6644",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "15%",
                }}
              >
                <img
                  src={MenuIcon1}
                  alt="Button Icon"
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                  }}
                />
              </div>
              <span style={ButtonTextStyle}>Asociar tarjetas</span>
            </Button>
          </Grid>
          {isDataAvailable && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={ButtonfieldStyles}
                  onClick={() => {
                    window.location.href = "/cargosrecurrentes";
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#0A6644",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "15%",
                    }}
                  >
                    <img
                      src={MenuIcon2}
                      alt="Button Icon"
                      style={{
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  </div>
                  <span style={ButtonTextStyle}>
                    Activar cargos recurrentes
                  </span>
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={ButtonfieldStyles}
                  onClick={() => {
                    window.location.href = "/desactivarcargosrecurrentes";
                  }}
                >
                  {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
                  <div
                    style={{
                      backgroundColor: "#0A6644",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "15%",
                    }}
                  >
                    <img
                      src={MenuIcon3}
                      alt="Button Icon"
                      style={{
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  </div>
                  <span style={ButtonTextStyle}>
                    Desactivar cargos recurrentes
                  </span>
                  {/* </Box> */}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={ButtonfieldStyles}
                  onClick={() => {
                    window.location.href = "/modificarcargosrecurrentes";
                  }}
                >
                  {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
                  <div
                    style={{
                      backgroundColor: "#0A6644",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "15%",
                    }}
                  >
                    <img
                      src={MenuIcon4}
                      alt="Button Icon"
                      style={{
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  </div>
                  <span style={ButtonTextStyle}>
                    Modificar cargos recurrentes
                  </span>
                  {/* </Box> */}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      <br />
      <br />
      <br />
    </RegisterFormWrapper>
  );
}

const RegisterFormWrapper = styled.div`
  width: 500px;
  height: 50vh;
  border: 3px solid #aecb35;
  border-radius: 20px;
  margin: auto;
  margin-top: 2vw;
  @media (max-width: 960px) {
    width: 400px;
  }
  @media (max-width: 640px) {
    margin-top: 50px;
    width: 350px;
  }
`;

const ButtonfieldStyles = {
  backgroundColor: "#FFFFFF", // Change the background color to white
  border: "3px solid #0A6644", // Add a 1px solid black border
  padding: "0px ", // Add padding to the button
  width: "420px", // Adjust the width of the button
  height: "7vh", // Adjust the height of the button
  display: "flex", // Center the content vertically and horizontally
  alignItems: "center",
  justifyContent: "flex-start",
  "&:hover": {
    backgroundColor: "#FFFFFF", // Change the background color on hover if needed
    border: "3px solid #0A6644", // Change the border color on hover if needed
  },
  "@media (max-width: 960px)": {
    width: "300px",
    height: "8vh",
  },
  "@media (max-width: 644px)": {
    width: "280px",
    height: "8vh",
  },
};

const ButtonTextStyle = {
  color: "#0A6644",
  marginLeft: "1.5rem",
  fontSize: "24px",
  lineHeight: "1.2", // Add the desired line height value
  fontFamily: "Brandon Grotesque, sans-serif",
  fontWeight: 400,
  textTransform: "none",
  textAlign: "left",
  "@media (max-width: 960px)": {
    fontSize: "18px",
    lineHeight: "1.1", // Adjusted line height for smaller screens
  },
};
