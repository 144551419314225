import React, { useEffect } from "react";
import Activarcargosrecurrentesdesktop from "../../Component/Activarcargosrecurrentes/Activarcargosrecurrentesdesktop";
import Layout from "../../Component/Layout/Layout";

function Activarcargosrecurrentes() {
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  return (
    <Layout>
      <Activarcargosrecurrentesdesktop />
    </Layout>
  );
}

export default Activarcargosrecurrentes;
