import styled from "styled-components";

export const TableComponent = styled.div`
  padding-top: 40px;
  @media (max-width: 960px) {
    padding-top: 40px;
  }
`;
export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  background-color: rgba(10, 102, 68, 1);
  color: white;
  height: 80px;
  @media (max-width: 960px) {
    height: 60px;
  }
  @media (max-width: 644px) {
    height: 40px;
  }
`;
export const NewTableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  background-color: rgba(10, 102, 68, 1);
  color: white;
  height: 80px;
`;
export const ModifyNewTableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  background-color: rgba(10, 102, 68, 1);
  color: white;
  height: 80px;
`;
export const TableHeaderModification = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  background-color: rgba(10, 102, 68, 1);
  color: white;
  height: 80px;
  @media (max-width: 960px) {
    height: 60px;
  }
  @media (max-width: 644px) {
    height: 80px;
  }
`;
export const TableHeaderModification2 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  background-color: rgba(10, 102, 68, 1);
  color: white;
  height: 80px;
  @media (max-width: 960px) {
    height: 60px;
  }
  @media (max-width: 644px) {
    height: 80px;
  }
`;

export const TableHeaderActive = styled.div`
  background-color: rgba(10, 102, 68, 1);
  color: white;
  height: 80px;
  text-align: left;
  display: flex;
  padding-left: 20px;
  @media (max-width: 960px) {
    height: 60px;
  }
  @media (max-width: 644px) {
    height: 40px;
  }
`;
export const TableBody = styled.div`
  a {
    text-decoration: none;
  }
`;
export const Textfield = styled.div`
  font-family: Brandon Grotesque;
  font-size: 32px;
  font-weight: 330;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a6644;
  @media (max-width: 960px) {
    font-size: 24px;
  }
  @media (max-width: 644px) {
    font-size: 18px;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > :not(:last-child) {
    margin-right: 20px;
  }
`;
export const Button = styled.button`
  font-family: Brandon Grotesque;
  font-size: 32px;
  font-weight: 330;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  width: 150px;
  height: 80px;
  top: 681px;
  left: 552px;
  border-radius: 15px;
  border: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0a6644;
  color: #ffffff;
  @media (max-width: 960px) {
    width: 100px;
    height: 65px;
    font-size: 24px;
  }
  @media (max-width: 644px) {
    width: 80px;
    height: 50px;
    font-size: 18px;
  }
  &:hover {
    background-color: grey;
  }
`;
export const TableBodyActive = styled.div`
  border: 3px solid rgba(174, 203, 53, 1);
`;
export const TableHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: 100px;
  line-height: 46px;
  letter-spacing: 0em;
  font-style: normal;

  @media (max-width: 960px) {
    font-size: 24px;
  }
  @media (max-width: 644px) {
    font-size: 18px;
  }
`;
export const TableBodyCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100px;
  line-height: 46px;
  letter-spacing: 0em;
  font-style: normal;
  color: rgba(10, 102, 68, 1);
  font-size: 16px;
  @media (max-width: 960px) {
    font-size: 20px;
  }
  @media (max-width: 644px) {
    font-size: 18px;
    line-height: 15px;
  }
`;
export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  color: white;
  height: 100px;
  border: 1px solid rgba(10, 102, 68, 1);
  max-height: 100px;
  overflow-y: auto;
  cursor: pointer;
`;
export const NewRow = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  color: white;
  height: 100px;
  border: 1px solid rgba(10, 102, 68, 1);
  max-height: 100px;
  overflow-y: auto;
  @media (max-width: 960px) {
    height: 85px;
  }
  @media (max-width: 644px) {
    height: 60px;
  }
`;
//modify changes
export const NewRowModi = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  color: white;
  height: 100px;
  border: 1px solid rgba(10, 102, 68, 1);
  max-height: 100px;
  overflow-y: auto;
  @media (max-width: 960px) {
    height: 85px;
  }
  @media (max-width: 644px) {
    height: 60px;
  }
`;
export const RowMod = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  color: white;
  height: 100px;
  border: 1px solid rgba(10, 102, 68, 1);
  max-height: 100px;
  overflow-y: auto;
`;
export const RowMod2 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  color: white;
  height: 100px;
  border: 1px solid rgba(10, 102, 68, 1);
  max-height: 100px;
  overflow-y: auto;
`;
export const ActiveUpperContainer = styled.div`
  padding: 10px 50px 30px 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  @media (max-width: 1350px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
  label {
    font-size: 28px;
    color: rgba(10, 102, 68, 1);
    @media (max-width: 1200px) {
      font-size: 20px;
      grid-gap: 10px;
    }
    @media (max-width: 960px) {
      font-size: 20px;
      grid-gap: 10px;
    }
    @media (max-width: 644px) {
      font-size: 18px;
      grid-gap: 10px;
    }
  }
  input {
    border: 2px solid rgba(174, 203, 53, 1);
    margin-right: 20px;
    font-size: 24px;
    color: rgba(10, 102, 68, 1);
    width: 180px;
    padding: 0 20px;
    text-align: right;
    height: 40px;
    @media (max-width: 960px) {
      width: 150px;
    }
    @media (max-width: 644px) {
      width: 150px;
    }
  }
  select {
    border: 2px solid rgba(174, 203, 53, 1);
    margin-right: 20px;
    font-size: 24px;
    color: rgba(10, 102, 68, 1);
    width: 223px;
    padding: 0 20px;
    text-align: right;
    height: 43px;

    @media (max-width: 960px) {
      width: 193px;
    }

    @media (max-width: 644px) {
      width: 194px;
    }
  }

  select option {
    font-size: 18px;
    color: rgba(10, 102, 68, 1);
    background-color: rgba(255, 255, 255, 1);
    border: none;
    padding: 10px;
  }

  select option:checked {
    background-color: rgba(174, 203, 53, 1);
    color: rgba(255, 255, 255, 1);
  }
`;
export const ActiveDownContainer = styled.div`
  padding: 20px 50px;
`;
export const AcctiveRow = styled.div`
  display: grid;
  grid-template-columns: 100px 225px 200px;
  grid-gap: 3px;
  font-size: 24px;
  color: rgba(10, 102, 68, 1);
  padding-bottom: 30px;
  @media (max-width: 960px) {
    grid-template-columns: 80px 250px 100px;
  }
  @media (max-width: 644px) {
    grid-template-columns: 80px 200px;
  }
`;
export const AcctiveColumn = styled.div`
  font-size: 28px;
  @media (max-width: 960px) {
    font-size: 24px;
  }
  @media (max-width: 644px) {
    font-size: 20px;
  }
  span {
    input {
      border: 2px solid rgba(174, 203, 53, 1);
      width: 80px;
      height: 35px;
      font-size: 20px;
      text-align: center;
      font-family: Brandon Grotesque;
      color: rgba(10, 102, 68, 1);
    }
  }
  input[type="radio"]:checked {
    background-color: rgba(10, 102, 68, 1) !important ; /* Green color */
    border-color: rgba(10, 102, 68, 1);
  }
  input {
    width: 35px;
    height: 35px;
    border: 2px solid rgba(174, 203, 53, 1);
  }
  label {
    padding: 0;
    margin: 0;
    font-weight: 900;
    font-size: 20px;
    @media (max-width: 644px) {
      font-size: 18px;
    }
  }
`;
export const Box = styled.div`
  background-color: #aecb35;
  border: 1px solid #ccc;
  padding: 20px;
  height: 80px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    height: 55px;
  }
  @media (max-width: 644px) {
    height: 35px;
  }
`;

export const Message = styled.p`
  font-family: Brandon Grotesque;
  font-size: 32px;
  font-weight: 330;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a6644;
  @media (max-width: 960px) {
    font-size: 18px;
  }
  @media (max-width: 644px) {
    font-size: 16px;
  }
`;

export const DuiWrapper = styled.div`
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  input {
    width: 350px;
    height: 50px;
    border-radius: 10px;
    border: none;
    text-align: center;
    font-size: 24px;
    background-color: lightgrey;
    color: #0a6644;
    margin: 10px 0;
    @media (max-width: 644px) {
      width: 250px;
    }
  }
  button {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #0a6644;
    cursor: pointer;
    margin: 20px 0;
    color: white;
    background-color: #0a6644;
    transition: 100ms;
    font-size: 20px;
    :hover {
      transform: scale(1.05);
    }
  }
`;
export const ModificaButton = styled.div`
  display: flex;
  justify-content: right;

  button {
    display: inline-block; /* This ensures the button takes only the width it needs */
    background-color: #0a6644;
    color: #ffffff;
    width: 12rem;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: 390;
    border-radius: 0.9rem;
    text-transform: none;
    font-style: normal;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 30px;
  }

  button:hover {
    background-color: #0a6644;
  }

  span {
    display: inline-block; /* This ensures the span takes only the width it needs */
    vertical-align: middle;
  }

  img {
    vertical-align: middle;
    margin-left: 0.5rem; /* Adjust the margin as needed */
  }
`;

export const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: ${({ isOpen }) =>
    isOpen ? 1 : 0}; /* Set initial opacity based on modal state */
  pointer-events: ${({ isOpen }) =>
    isOpen
      ? "auto"
      : "none"}; /* Enable/disable interactions based on modal state */
  transition: opacity 0.3s, pointer-events 0.3s; /* Smooth transition effect */

  /* Other styles for the modal content */
`;

export const ModalContent = styled.div`
  display: grid;
  grid-template-rows: 65% 35%;
  width: 350px;
  height: 150px;
  background-color: #d8d8d8;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  @media (max-width: 960px) {
    width: 300px;
  }
  @media (max-width: 644px) {
    width: 250px;
  }
`;
export const ModalText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 0;
  margin: 0;
  color: #0a6644;
  font-weight: 900;
`;
export const ModalButtonContainer = styled.div`
  border-top: 2px solid #0a6644;
`;
export const ModalButtonContainerDelete = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #0a6644;
`;
export const ModalButton = styled.p`
  font-size: 20px;
  font-weight: 900;
  color: #0a6644;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding-top: 10px;
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
`;
export const Modal2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: ${({ isOpen }) =>
    isOpen ? 1 : 0}; /* Set initial opacity based on modal state */
  pointer-events: ${({ isOpen }) =>
    isOpen
      ? "auto"
      : "none"}; /* Enable/disable interactions based on modal state */
  transition: opacity 0.3s, pointer-events 0.3s; /* Smooth transition effect */

  /* Other styles for the modal content */
`;

export const ModalContent2 = styled.div`
  display: grid;
  grid-template-rows: 90% 10%;
  width: 350px;
  height: 450px;
  background-color: #d8d8d8;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  @media (max-width: 960px) {
    height: 500px;
    width: 300px;
  }
  @media (max-width: 644px) {
    width: 250px;
  }
`;
export const ModalText2 = styled.p`
  color: #0a6644;
  font-weight: 400;
`;
export const ModalButtonContainer2 = styled.div`
  border-top: 2px solid #0a6644;
`;

export const ModalButton2 = styled.p`
  font-size: 20px;
  font-weight: 900;
  color: #0a6644;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding-top: 10px;
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
`;
export const AddcardModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
  transition: opacity 0.3s, pointer-events 0.3s; /* Smooth transition effect */
`;
export const AddcardModalContent = styled.div`
  width: 60%;
  height: auto;
  background-color: #d8d8d8;
  padding: 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  @media (max-width: 1050px) {
    width: 80%;
  }
  @media (max-width: 644px) {
    width: 80%;
  }
`;

export const CloseButton = styled.button`
  background-color: #0a6644;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin: 5px 0;
  margin-left: auto; /* Add this line to align the button to the right */

  &:hover {
    background-color: #0a6644;
    opacity: 0.9;
  }
`;
export const ModalHeadder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
`;
export const ModalTop = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
`;

export const AddWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3 1fr);
  grid-gap: 20px;
  width: 90%;
  @media (max-width: 644px) {
    width: 98%;
  }
`;
export const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 0.5fr;
  grid-gap: 10px;
  @media (max-width: 550px) {
    grid-template-columns: 2fr 2fr 1fr;
  }
`;
export const OtpModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
  transition: opacity 0.3s, pointer-events 0.3s; /* Smooth transition effect */
`;
export const OtpModalContent = styled.div`
  width: 25%;
  height: auto;
  background-color: #d8d8d8;
  padding: 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  @media (max-width: 1100px) {
    width: 30%;
  }
  @media (max-width: 960px) {
    width: 40%;
  }
  @media (max-width: 700px) {
    width: 50%;
  }
  @media (max-width: 644px) {
    width: 70%;
  }
`;
export const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

export const NewTableHeaderHistory = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  background-color: rgba(10, 102, 68, 1);
  color: white;
  height: 80px;
`;
export const NewRowHistory = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  color: white;
  height: 100px;
  border: 1px solid rgba(10, 102, 68, 1);
  max-height: 100px;
  overflow-y: auto;
  @media (max-width: 960px) {
    height: 85px;
  }
  @media (max-width: 644px) {
    height: 60px;
  }
`;
