import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login/LoginPage";
import Register from "./Pages/Register/RegisterPage";
import Logo from "./Const/Logo";
import MenuTokenization from "./Pages/MenuTokenization/MenuTokenization";
import MisTarjetas from "./Pages/MisTarjetas/MisTarjetas";
import PieDePagina from "./Pages/PieDePagina/PieDePagina";
import Cargosrecurrentes from "./Pages/Cargosrecurrentes/Cargosrecurrentes";
import Activarcargosrecurrentes from "./Pages/Activarcargosrecurrentes/Activarcargosrecurrentes";
import Desactivarcargosrecurrentes from "./Pages/Desactivarcargosrecurrentes/Desactivarcargosrecurrentes";
import Modificarcargosrecurrentes from "./Pages/Modificarcargosrecurrentes/Modificarcargosrecurrentes";
import ModificarForm from "./Component/Modificarcargosrecurrentes/ModificarForm";
import OTP from "./Component/Register/OTP";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import PaymentHistory from "./Pages/PaymentHistory/PaymentHistory";

function App() {
  //const currentPath = window.location.pathname;
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Login />}></Route>
          <Route path="/register" element={<Register />} />
          <Route path="/mis-tarjetas" element={<MisTarjetas />} />
          <Route path="/pie-de-pagina" element={<PieDePagina />} />
          <Route path="/cargosrecurrentes" element={<Cargosrecurrentes />} />
          <Route
            path="/activarcargosrecurrentes/:asecode/:poliza/:ramo/:monto/:vence/:nombre/:idProcessPayment/:rowIndex/:dui/:cuotaData"
            element={<Activarcargosrecurrentes />}
          />
          <Route
            path="/modificarcargosrecurrentes"
            element={<Modificarcargosrecurrentes />}
          />
          <Route
            path="/modificarcargosrecurrentes/form/:policy_no/:date/:amount/:token"
            element={<ModificarForm />}
          />
          <Route path="/menu" element={<MenuTokenization />} />
          <Route
            path="/desactivarcargosrecurrentes"
            element={<Desactivarcargosrecurrentes />}
          />
          <Route path="/otpscreen" element={<OTP />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/paymenthistory" element={<PaymentHistory/>} />
        </Routes>
        <Routes></Routes>
      </div>
    </Router>
  );
}

export default App;
