import React, { useEffect } from "react";
import styled from "styled-components";
import Menu from "../../Component/MenuTokenization/MenuTokenization";
import Layout from "../../Component/Layout/Layout";
import { IconButton } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; // Import the logout icon
import Namecontainer from "../../Component/Namecontainer/Namecontainer";
import { Button, Typography, Grid } from "@mui/material";

export default function MenuPage() {
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };
  return (
    <div>
      <Namecontainer />
      <Layout>
        <MenuFormWrapper
          style={{
            display: "flex",
            alignContent: "center",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: "10px",
          }}
        >
          <div>
            <Menu />
          </div>
          {/* <div>
            <IconButton
              onClick={handleLogout}
              style={{
                marginLeft: "700px",
                marginTop: "0px",
                color: "green",
              }}
            >
              <ExitToAppIcon style={{ fontSize: "40px" }} />
            </IconButton>
          </div> */}
        </MenuFormWrapper>
        <style>
          {`
    @media (max-width: 600px) {
      marginLeft: "29px"
    `}
        </style>
      </Layout>
    </div>
  );
}

const MenuFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
`;

const ButtonfieldStyles = {
  margin: "10px",
  backgroundColor: "#FFFFFF", // Change the background color to white
  border: "3px solid #0A6644", // Add a 1px solid black border
  padding: "0px ", // Add padding to the button
  width: "150px", // Adjust the width of the button
  height: "7vh", // Adjust the height of the button
  display: "flex", // Center the content vertically and horizontally
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#FFFFFF", // Change the background color on hover if needed
    border: "3px solid #0A6644", // Change the border color on hover if needed
  },
  "@media (max-width: 960px)": {
    width: "300px",
    height: "8vh",
  },
  "@media (max-width: 644px)": {
    width: "280px",
    height: "8vh",
  },
};

const ButtonTextStyle = {
  color: "#0A6644",
  fontSize: "17px",
  lineHeight: "1.2", // Add the desired line height value
  fontFamily: "Brandon Grotesque, sans-serif",
  fontWeight: 400,
  textTransform: "none",
  textAlign: "left",
  "@media (max-width: 960px)": {
    fontSize: "18px",
    lineHeight: "1.1", // Adjusted line height for smaller screens
  },
};
