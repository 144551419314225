import React from "react";
import styled from "styled-components";
import Register from "../../Component/Register/Register";
import LayoutLogin from "../../Component/Layout/LayoutLogin";

export default function RegisterPage() {
  return (
    <LayoutLogin>
      <RegisterFormWrapper>
        <Register />
      </RegisterFormWrapper>
    </LayoutLogin>
  );
}

const RegisterFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
`;
