import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from "@mui/material";
import RegisterIcon4 from "../../Assets/Register/registericon4.png";
import RegisterIcon2 from "../../Assets/Register/registericon2.png";
import { ValidateEmail, VerifyOtp } from "../../api/Axios";
import PhoneInput from "react-phone-input-2";
import { useHistory, Link } from "react-router-dom";

function Forget() {
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [otp, setotp] = useState("");
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [errmodalOpen, seterrModalOpen] = useState(false);
  const [modalOpenerr, setModalOpenerr] = useState(false);
  const phoneNumberRef = useRef(null);
  const faxRef = useRef(null);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  console.log(phone_number);

  const findFormErrors = () => {
    const newErrors = {};
    if (!email || email === "")
      newErrors.email = "¡Correo electronico es requerido!";
    else if (!/\S+@\S+\.\S+/.test(email))
      newErrors.email = "¡El correo electrónico es invalido!";
    // Validate phone number
    if (!phone_number || phone_number === "") {
      newErrors.phone_number = "¡El número de teléfono es obligatorio!";
    } else if (!/^[0-9]+$/.test(phone_number)) {
      newErrors.phone_number =
        "¡El número de teléfono debe contener solo dígitos!";
    } else if (phone_number.length !== 11) {
      newErrors.phone_number = "¡El número de teléfono debe tener 10 dígitos!";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    setErrors(newErrors);
    // Check if there are no errors before submitting
    if (Object.keys(newErrors).length === 0) {
      await sendUserData();
    }
  };
  const handleotp = async (e) => {
    console.log("he");
    handleCloseModal();
    await sendData();
  };

  const sendData = async () => {
    const Data = {
      email: email,
      otp: otp,
    };
    console.log(Data);
    try {
      const res = await VerifyOtp(Data); // Assuming Regestration is correctly defined
      console.log(res);
      if (res.data.status === true) {
        window.location.href = `/resetpassword?email=${email}`;
      } else {
        setModalOpenerr(true);
      }
    } catch (err) {
      setModalOpenerr(true);
      setotp("");
      console.log(err);
    }
  };

  const sendUserData = async () => {
    const userData = {
      email: email,
      login_status: "emailType",
      phone_number: phone_number,
    };
    try {
      const res = await ValidateEmail(userData); // Assuming Regestration is correctly defined
      // console.log(res.data.status);
      if (res.data.status === true) {
        setModalOpen(true);
      } else {
        seterrModalOpen(true);
      }
    } catch (err) {}
  };

  return (
    <div>
      <RegisterFormWrapper>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{
              width: { md: "29.1vw", sm: "38.1vw", xs: "48.1vw" },
              margin: "auto",
              "& .MuiGrid-item": {
                paddingLeft: "0px",
                paddingTop: "0px",
              },
            }}
          >
            <Grid item xs={12}>
              {/* Register title */}
              <Typography
                component="h1"
                variant="h4"
                align="center"
                sx={{
                  color: "#0A6644",
                  fontSize: "1.5rem",
                  fontWeight: 650,
                  marginTop: "1vw",
                  marginBottom: "0.1vw",
                  fontFamily: "Brandon Grotesque",
                }}
              >
                <b>Forget Password</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                sx={TextfieldStyles}
                variant="outlined"
                margin="normal"
                id="email"
                label="email"
                name="email"
                type="email"
                value={email}
                helperText={errors.email}
                error={errors.email !== undefined}
                onChange={(e) => setemail(e.target.value)}
                autoComplete="email"
                placeholder="Correo electrónico"
                InputProps={{
                  style: {
                    color: "#0A6644",
                    fontFamily: "Brandon Grotesque",
                  },
                  startAdornment: (
                    <div style={{ backgroundColor: "#0A6644", height: "98%" }}>
                      <img
                        src={RegisterIcon2}
                        style={{ height: "98%" }}
                        alt="Email"
                      />
                    </div>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={120}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "0.9rem",
              }}
            >
              <Country>
                <PhoneInput
                  country={"es"}
                  inputRef={phoneNumberRef}
                  inputProps={{
                    onKeyDown: (event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        faxRef.current.focus();
                      }
                    },
                  }}
                  enableAreaCodes={true}
                  placeholder="Teléfono"
                  helperText={errors.phone_number}
                  error={errors.phone_number !== undefined}
                  value={phone_number}
                  onChange={(phone_number) => setphone_number(phone_number)}
                  variant="outlined"
                  inputClass="phone_input"
                  inputStyle={{
                    display: "flex",
                    fontFamily: "Roboto",
                    border: "3px solid #0A6644", // Updated border style
                    backgroundColor: "transparent", // Transparent background
                    color: "#0A6644", // Text color
                    fontSize: "1.4rem", // Font size
                    paddingLeft: "160px", // Padding on the left
              
                  }}
                />
              </Country>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Correct data button */}
              <ButtonWrapper>
                <Button
                  sx={{
                    ...buttonstyle,
                    "@media (max-width: 1124px)": {
                      width: "21vw",
                      // width: "80%",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 985px)": {
                      width: "22vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 950px)": {
                      width: "23vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 910px)": {
                      width: "24vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 875px)": {
                      width: "30vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 700px)": {
                      width: "35vw",
                      fontSize: "0.9rem",
                    },
                  }}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Grid>
          </Grid>
        </form>
      </RegisterFormWrapper>
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>Por favor revise su correo electrónico</DialogTitle>
        <DialogContent>
          <DialogContentText>Por favor ingrese un dígito:</DialogContentText>
          <TextField
            fullWidth
            margin="dense"
            value={otp}
            variant="outlined"
            placeholder="Por favor ingrese un dígito:"
            onChange={(e) => setotp(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          <Button onClick={handleotp} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errmodalOpen} onClose={() => seterrModalOpen(false)}>
        <DialogTitle>
          <Typography variant="h6" color="error">
            Error: Detalles incorrectos...!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Los datos que ingresaste son incorrectos. Inténtalo de nuevo.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => seterrModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={modalOpenerr} onClose={() => setModalOpenerr(false)}>
        <DialogTitle>
          <Typography variant="h6" color="error">
            Error: Dígito incorrecto
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              El dígito que ingresó es incorrecto. Inténtalo de nuevo.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpenerr(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Forget;

const modalContentStyle = {
  top: "100px",
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderRadius: "10px",
  height: "100px",
};

const RegisterFormWrapper = styled.div`
  width: 34vw;
  height: auto;
  border: 3px solid #aecb35;
  border-radius: 20px;
  margin: auto;
  margin-top: 2vw;
  padding-bottom: 2vw;

  @media (max-width: 1124px) {
    width: 33vw;
    height: auto;
  }
  @media (max-width: 985px) {
    width: 50vw;
    height: auto;
  }
  @media (max-width: 650px) {
    width: 80vw;
    height: auto;
  }
`;
const TextfieldStyles = {
  "& .MuiFormControl-root": {
    "& .MuiTextField-root": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#0A6644",
    opacity: 1, // Set opacity to 1 to ensure the color is fully visible
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #0A6644",
    backgroundColor: "transparent",
    padding: "0 1px",
    top: "-4px",
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiInputBase-root ": {
    paddingLeft: "0px",
    width: { md: "29vw", sm: "38vw", xs: "48vw" },
    height: { md: "7.5vh", sm: "6vh", xs: "5vh" },
    // marginBottom: "1vh",
  },
  "& .MuiInputBase-input": {
    paddingLeft: "1.25rem",
    fontSize: "1.4rem",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "3px solid #0A6644",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "3px solid #0A6644",
    },
  },
  "& fieldset": {
    borderColor: "#0A6644", // Set the border color for the default state
    legend: {
      width: "0px",
    },
  },
  "&:hover fieldset": {
    borderColor: "#0A6644", // Set the border color for the hover state
  },
};
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  /* width: 20vw; */
  margin: auto;
  padding-top: 1.5vw;
`;

const buttonstyle = {
  backgroundColor: "#0A6644",
  width: "20vw",
  height: "7vh",
  fontSize: "1rem",
  boxshadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "0px",
  fontFamily: "Brandon Grotesque",
  "&:hover": {
    backgroundColor: "#0A6644",
  },
  margin: "0 auto",
};
const Country = styled.div`
  .react-tel-input .form-control {
    @media (max-width: 1444px) {
      width: 29vw;
    }
    @media (max-width: 1024px) {
      padding-left: 130px;
    }
    // @media (max-width: 905px) {
    //   width: 38vw;
    //   height: 5.8vh;
    // }
    @media (max-width: 330px) {
      width: 38vw;
      height: 5.8vh;
    }

    border: 3px solid #0a6644 !important;
    width: 29vw;
    height: 7.5vh;
  }

  .react-tel-input .flag-dropdown {
    @media (max-width: 768px) {
      width: 60px;
    }
    @media (max-width: 425px) {
      width: 50px;
    }
    top: 0;
    bottom: 0;
    width: 77px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${RegisterIcon4}) !important;
    border: 3px #0a6644 !important;
  }
  .kPDUHs .react-tel-input .flag-dropdown {
    top: 0px;
    bottom: 0px;

    background-color: rgb(10, 102, 68) !important;
    border: 3px rgb(10, 102, 68) !important;
  }
  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: transparent;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    display: flex;
    width: inherit;
    border-radius: 3px 0 0 0;
  }
  .react-tel-input .selected-flag:focus {
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    position: relative;
    width: inherit;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
  }
  .ehjwoZ .react-tel-input .flag-dropdown.open .selected-flag {
    background: rgb(10, 102, 68);
    border-radius: 3px 0px 0px;
  }
  .react-tel-input .selected-flag:hover .react-tel-input .selected-flag:focus {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
  }
  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    left: 50px;
    border-radius: 3px 0 0 3px;
  }



  @media (max-width: 2560px) {
    .react-tel-input .form-control {
      padding-left: 125px !important;
    }
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      left: 90px;
      border-radius: 3px 0 0 3px;
    }
  }

  @media (max-width: 1440px) {
    .react-tel-input .form-control {
      padding-left: 125px !important;
    }
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      left: 90px;
      border-radius: 3px 0 0 3px;
    }
  }

  @media (max-width: 1024px) {
    .react-tel-input .form-control {
      padding-left: 125px !important;
    }
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      left: 90px;
      border-radius: 3px 0 0 3px;
    }
  }

  @media (max-width: 768px) {
    .react-tel-input .form-control {
      padding-left: 110px !important;
      
    }
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      left: 75px;
      border-radius: 3px 0 0 3px;
    }
  }


  @media (max-width: 576px) {
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      left: 50px;
      border-radius: 3px 0 0 3px;
    }

    .react-tel-input .form-control {
      padding-left: 85px !important;
      width: 48vw; // Adjust width for extra small screens
      height: 1vh; // Adjust height for extra small screens
    }
  }
`;
