import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import {
  Modal,
  ModalContent,
  ModalText,
  ModalButton,
  ModalButtonContainerDelete,
  ModalButtonContainer,
} from "../TableStyle/Tablestyle";
import Card from "../../Assets/card.png";
import Delete from "../../Assets/delete.png";
import { deleteCard, getAllCardById } from "../../api/Axios";

const DisplayCardDetails = () => {
  const [cardList, setCardList] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletingCardId, setDeletingCardId] = useState(null);
  const [deleteErrorAlert, setDeleteErrorAlert] = useState(false);
  const [deleteErrorAlertMessage, setDeleteErrorAlertMessage] = useState("");
  const [isOpenModal, setIsopenModal] = useState(false);
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    getAllCardById(userId)
      .then((res) => {
        setCardList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  const handleDeleteCard = (cardId) => {
    setShowConfirmation(true);
    setDeletingCardId(cardId);
  };

  const handleConfirmDelete = () => {
    if (deletingCardId) {
      const selectedCard = cardList.find(
        (card) => card.card_id === deletingCardId
      );

      if (selectedCard) {
        const merchantId = selectedCard.merchant_id;
        const cardToken = selectedCard.token;

        const requsetData = {
          merchant_id: merchantId,
          token: cardToken,
        };

        deleteCard(deletingCardId, requsetData)
          .then((response) => {
            console.log(response);
            if (response.data.success === true) {
              getAllCardById(userId).then((res) => {
                setCardList(res.data.data);
                if (res.data.success === true) {
                  setDeleteErrorAlertMessage(
                    // "Tarjeta eliminada con éxito: ¡échale un vistazo!"
                    "Tarjeta eliminada con éxito"
                  );
                  setIsopenModal(true);
                } else {
                  // setDeleteErrorAlertMessage(res.data.message);
                  // setIsopenModal(true);
                }
              });
            } else {
              setDeleteErrorAlert(true);
              setDeleteErrorAlertMessage(
                "La tarjeta tiene cargos asociados, debe desactivarlos primero"
              );
              setIsopenModal(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    setShowConfirmation(false);
    setDeletingCardId(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setDeletingCardId(null);
  };

  return (
    <Grid
      container
      sx={{
        border: "2px solid #0A6644",
        padding: "1rem",
        margin: "0.5rem 0",
      }}
    >
      {cardList.length > 0 ? (
        cardList.map((card) => (
          <Grid
            container
            alignItems="center"
            spacing={1}
            key={card.card_id}
            mb={1}
          >
            <Grid item xs={3} sm={2} md={1}>
              <img
                src={Card}
                alt="Card"
                style={{
                  maxWidth: "15vw",
                  width: "auto",
                  height: "auto",
                  padding: "0rem 2.3rem",
                }}
                sx={{
                  "@media (min-width: 960px)": {
                    maxWidth: "2.9vw",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "390px",
                  color: "#0A6644",
                  fontStyle: "normal",
                  marginLeft: { xs: "1rem", sm: "2rem" },
                }}
              >
                {card.account_no
                  .slice(-4)
                  .padStart(card.account_no.length, "*")}
              </Typography>
            </Grid>
            <Grid item xs={9} sm={9} md={6}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "390px",
                  color: "#0A6644",
                  fontStyle: "normal",
                }}
              >
                Fecha vencimiento: {card.expiration_date.slice(0, 2)}/
                {card.expiration_date.slice(2)}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={1}>
              <img
                src={Delete}
                alt="delete icon"
                style={{
                  maxWidth: "15vw",
                  width: "auto",
                  height: "auto",
                  padding: "0rem 2.3rem",
                  cursor: "pointer",
                }}
                sx={{
                  "@media (min-width: 960px)": {
                    maxWidth: "2.9vw",
                  },
                }}
                onClick={() => {
                  if (!deleteErrorAlert) {
                    handleDeleteCard(card.card_id);
                  }
                }}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography>No hay tarjetas para mostrar</Typography>
        </Grid>
      )}
      <Modal isOpen={showConfirmation}>
        <ModalContent>
          <ModalText>
          {/* Tarjeta elminada con éxito */}
          ¿Está seguro que quiere 
          <br />  eliminar esta tarjeta?
          </ModalText>
          <ModalButtonContainerDelete>
            <ModalButton
              style={{ borderRight: "1px solid #0a6644" }}
              onClick={handleConfirmDelete}
            >
              Sí
            </ModalButton>
            <ModalButton onClick={handleCancelDelete}>No</ModalButton>
          </ModalButtonContainerDelete>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenModal}>
          <ModalContent>
            <ModalText>{deleteErrorAlertMessage}</ModalText>
            <ModalButtonContainer>
              <ModalButton
                onClick={() => {
                  setIsopenModal(false);
                }}
              >
                Aceptar
              </ModalButton>
            </ModalButtonContainer>
          </ModalContent>
        </Modal>
    </Grid>
  );
};

export default DisplayCardDetails;
