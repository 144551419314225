import React from "react";
import styled from "@emotion/styled";
import backgroundImage from "../../Assets/LoginPage/background.svg";
import Login from "../../Component/Login/Login";
import LayoutLogin from "../../Component/Layout/LayoutLogin";

export default function LoginPage() {
  return (
    <Warapper>
    <LayoutLogin>
      <LoginPageContainer>
        <Login />
      </LoginPageContainer>
    </LayoutLogin>
    </Warapper>
  );
}

const LoginPageContainer = styled.div`

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
`;
const Warapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  height: 100vh;
`