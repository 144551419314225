import React from "react";
import { Grid, Typography } from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const BottomBar = () => {
  return (
    <Grid
      container
      sx={{
        position: "fixed", // Make the container fixed at the bottom
        bottom: 0, // Position it at the bottom
        width: "100%", // Full width
        backgroundColor: "#0A6644",
        padding: "1rem 0",
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight:  { xs: "5px", sm: "10px", md: "30px" }
        }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: { xs: "0.6rem", sm: "1rem", md: "1.5rem" },
            color: "#FFFFFF",
            fontStyle: "normal",
            display: "flex",
            alignItems: "center",
          }}
        >
          <LanguageOutlinedIcon
            sx={{
              fontSize: "2rem",
              marginRight: "0.5rem",
            }}
          />
          www.segurosfedecredito.com
        </Typography>
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography
          variant="p"
          sx={{
            fontSize: { xs: "0.6rem", sm: "1rem", md: "1.5rem" },
            color: "#FFFFFF",
            fontStyle: "normal",
            display: "flex",
            alignItems: "center",
          }}
        >
          <EmailOutlinedIcon
            sx={{
              fontSize: "2rem",
              marginRight: "0.5rem",
            }}
          />
          helpdesk@segurosfedecredito.com
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BottomBar;
