import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  //config
  apiKey: "AIzaSyCf6Yc5fGlNzV0Izrwqcw7nto6odILw1HY",
  authDomain: "seguros-app-3693d.firebaseapp.com",
  projectId: "seguros-app-3693d",
  storageBucket: "seguros-app-3693d.appspot.com",
  messagingSenderId: "458594300357",
  appId: "1:458594300357:web:7a1f6ccb98886988dc9bac"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);