import React, { useEffect } from 'react'
import PieDePaginaPage from '../../Component/PieDePagina/PieDePaginaPage'

export default function PieDePagina() {
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  return (
    <div>
        <PieDePaginaPage/>
    </div>
  )
}
