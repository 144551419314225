import axios from "axios";
const token = localStorage.getItem("token");

export const api = axios.create({
  baseURL: "https://backend.appsegurosfedecreditotest.com/",
  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `${token}`,
    "Content-type": "application/json",
  },
});

export const registerAPI = axios.create({
  baseURL: "https://backend.appsegurosfedecreditotest.com/",
  timeout: 30000,
  headers: {
    "Content-type": "application/json",
  },
});

//Regestration
export const Regestration = async (formData) => {
  const res = await registerAPI.post(`/user/register`, formData);
  return res;
};
//Login
export const LoginApi = async (formData) => {
  const res = await registerAPI.post(`/user/login`, formData);
  return res;
};

export const deleteMemberById = async (id) => {
  const res = await api.delete(`/mem/deletemem/${id}`);
  return res;
};

//Create Card
export const createCard = async (requestData) => {
  const res = await api.post(`/soap/createClient`, requestData);
  return res;
};

export const verifyClient = async (requestData) => {
  const res = await api.post(`/soap/verifyClient`, requestData);
  return res;
};

//Delete Card
export const deleteCard = async (deletingCardId, requsetData) => {
  const res = await api.post(
    `/soap/deleteClient/${deletingCardId}`,
    requsetData
  );
  return res;
};

//get All Cards
export const getAllCardById = async (userId) => {
  const res = await api.get(`/card/getcard_detail/${userId}`);
  return res;
};

//get policy
export const getPolicy = async (policyNo) => {
  const res = await api.get(
    `/filter/searchPolicyToPays/documentId/${policyNo}`
  );
  return res;
};

//get All Auto Payments
export const getAutoPaymentById = async (userId) => {
  const res = await api.get(`autopayment/get_autopayment_userid/${userId}`);
  return res;
};

//delete Auto Payments by Id
export const deleteAutoPaymentById = async (deletingAutoPaymentId) => {
  const res = await api.delete(
    `/autopayment/delete_autopayment/${deletingAutoPaymentId}`
  );
  return res;
};

//get Card details by user id
export const getCardById = async (userId) => {
  const res = await api.get(`/card/getcard_detail/${userId}`);
  return res;
};
//auto payment
export const createautopayment = async (requestData) => {
  const res = await api.post(`/autopayment/create_autopayment`, requestData);
  return res;
};
//auto payment modification
export const EditAutoPayment = async (policyNo, values) => {
  const res = await api.put(
    `/autopayment/update_autopayment/${policyNo}`,
    values
  );
  return res;
};

//get all auto payment
export const getallAutoPayment = async () => {
  const res = await api.get(`/autopayment/getall_autopayment`);
  return res;
};

//Forget password
export const ValidateEmail = async (Data) => {
  const res = await api.post(
    `/user/check_email_phone_for_reset_password`,
    Data
  );
  return res;
};
//Verify Otp
export const VerifyOtp = async (Data) => {
  const res = await api.post(`/user/verify_otp`, Data);
  return res;
};
//Reset password
export const Resetpassword = async (Data) => {
  const res = await registerAPI.put(`user/reset_password`, Data);
  return res;
};
//validate payment
export const validateautopayment = async (requestData) => {
  const res = await api.post(`filter/getValidatePayment`, requestData);
  return res;
};
//get payment history
export const getAllPaymentHistory = async () => {
  const res = await api.get(`/autopayment/getall_paymentHistory`);
  return res;
};
