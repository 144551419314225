import React, { useState } from "react";
import styled from "styled-components";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from "@mui/material";
import RegisterIcon3 from "../../Assets/Register/registericon3.png";
import { Resetpassword } from "../../api/Axios";
import { useLocation } from "react-router-dom";

function Reset() {
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenerr, setModalOpenerr] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  const handleCloseModal = () => {
    setModalOpen(false);
    window.location.href = `/`;
  };
  const handleCloseModalerr = () => {
    setModalOpenerr(false);
  };

  const findFormErrors = () => {
    const newErrors = {};

    // Validate password
    if (!password || password === "") {
      newErrors.password = "¡Se requiere contraseña!";
    } else if (password.length < 8) {
      newErrors.password = "¡La contraseña debe tener al menos 8 caracteres!";
    } else if (!/[A-Z]/.test(password)) {
      newErrors.password =
        "¡La contraseña debe contener al menos una letra mayúscula!";
    } else if (!/[a-z]/.test(password)) {
      newErrors.password =
        "¡La contraseña debe contener al menos una letra minúscula!";
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
      newErrors.password =
        "¡La contraseña debe contener al menos un carácter especial!";
    }

    if (!confirmpassword || confirmpassword === "") {
      newErrors.confirmpassword = "¡Se requiere contraseña!";
    } else if (confirmpassword.length < 8) {
      newErrors.confirmpassword =
        "¡La contraseña debe tener al menos 8 caracteres!";
    } else if (!/[A-Z]/.test(confirmpassword)) {
      newErrors.confirmpassword =
        "¡La contraseña debe contener al menos una letra mayúscula!";
    } else if (!/[a-z]/.test(confirmpassword)) {
      newErrors.confirmpassword =
        "¡La contraseña debe contener al menos una letra minúscula!";
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(confirmpassword)) {
      newErrors.confirmpassword =
        "¡La contraseña debe contener al menos un carácter especial!";
    } else if (!confirmpassword || confirmpassword !== password) {
      newErrors.confirmpassword = "La contraseña no coincide";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    setErrors(newErrors);
    // Check if there are no errors before submitting
    if (Object.keys(newErrors).length === 0) {
      await sendUserData();
    }
  };

  const sendUserData = async () => {
    const Data = {
      email: email,
      password: password,
      confirmPassword: confirmpassword,
    };
    try {
      const res = await Resetpassword(Data);
      console.log(res.data.status);
      if (res.data.status === "Success") {
        setModalOpen(true);
      } else {
        setModalOpenerr(true);
      }
    } catch (err) {
      setModalOpenerr(true);
    }
  };

  return (
    <div>
      {" "}
      <RegisterFormWrapper>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{
              width: { md: "29.1vw", sm: "38.1vw", xs: "48.1vw" },
              margin: "auto",
              "& .MuiGrid-item": {
                paddingLeft: "0px",
                paddingTop: "0px",
              },
            }}
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                sx={{
                  color: "#0A6644",
                  fontSize: "1.5rem",
                  fontWeight: 650,
                  marginTop: "1vw",
                  marginBottom: "0.1vw",
                  fontFamily: "Brandon Grotesque",
                }}
              >
                <b>Reset Password</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Pass word */}
              <TextField
                sx={TextfieldStyles}
                variant="outlined"
                margin="normal"
                id="password"
                label="password"
                name="password"
                type="password"
                helperText={errors.password}
                error={errors.password !== undefined}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="password"
                placeholder="Contraseña"
                InputProps={{
                  style: {
                    color: "#0A6644",
                    fontFamily: "Brandon Grotesque",
                  },
                  startAdornment: (
                    <div style={{ backgroundColor: "#0A6644", height: "98%" }}>
                      <img
                        src={RegisterIcon3}
                        style={{
                          height: "98%",
                        }}
                        alt="password"
                      />
                    </div>
                  ),
                }}
              />
            </Grid>{" "}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/*Confirm Pass word */}
              <TextField
                sx={TextfieldStyles}
                variant="outlined"
                margin="normal"
                id="confirmpassword"
                label="confirmpassword"
                name="confirmpassword"
                type="password"
                helperText={errors.confirmpassword}
                error={errors.confirmpassword !== undefined}
                value={confirmpassword}
                onChange={(e) => setconfirmPassword(e.target.value)}
                autoComplete="password"
                placeholder="Confirm Contraseña"
                InputProps={{
                  style: {
                    color: "#0A6644",
                    fontFamily: "Brandon Grotesque",
                  },
                  startAdornment: (
                    <div style={{ backgroundColor: "#0A6644", height: "98%" }}>
                      <img
                        src={RegisterIcon3}
                        style={{
                          height: "98%",
                        }}
                        alt="password"
                      />
                    </div>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Correct data button */}
              <ButtonWrapper>
                <Button
                  sx={{
                    ...buttonstyle,
                    "@media (max-width: 1124px)": {
                      width: "21vw",
                      // width: "80%",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 985px)": {
                      width: "22vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 950px)": {
                      width: "23vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 910px)": {
                      width: "24vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 875px)": {
                      width: "30vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 700px)": {
                      width: "35vw",
                      fontSize: "0.9rem",
                    },
                  }}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Grid>
          </Grid>
        </form>
      </RegisterFormWrapper>
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>restablecimiento de contraseña exitoso</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={modalOpenerr} onClose={handleCloseModalerr}>
        <DialogTitle>
          Password didn't reset successfully, please try again
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseModalerr} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Reset;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  /* width: 20vw; */
  margin: auto;
  padding-top: 1.5vw;
`;

const buttonstyle = {
  backgroundColor: "#0A6644",
  width: "20vw",
  height: "7vh",
  fontSize: "1rem",
  boxshadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "0px",
  fontFamily: "Brandon Grotesque",
  "&:hover": {
    backgroundColor: "#0A6644",
  },
  margin: "0 auto",
};

const TextfieldStyles = {
  "& .MuiFormControl-root": {
    "& .MuiTextField-root": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#0A6644",
    opacity: 1, // Set opacity to 1 to ensure the color is fully visible
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #0A6644",
    backgroundColor: "transparent",
    padding: "0 1px",
    top: "-4px",
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiInputBase-root ": {
    paddingLeft: "0px",
    width: { md: "29vw", sm: "38vw", xs: "48vw" },
    height: { md: "7.5vh", sm: "6vh", xs: "5vh" },
    // marginBottom: "1vh",
  },
  "& .MuiInputBase-input": {
    paddingLeft: "1.25rem",
    fontSize: "1.4rem",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "3px solid #0A6644",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "3px solid #0A6644",
    },
  },
  "& fieldset": {
    borderColor: "#0A6644", // Set the border color for the default state
    legend: {
      width: "0px",
    },
  },
  "&:hover fieldset": {
    borderColor: "#0A6644", // Set the border color for the hover state
  },
};

const RegisterFormWrapper = styled.div`
  width: 34vw;
  height: auto;
  border: 3px solid #aecb35;
  border-radius: 20px;
  margin: auto;
  margin-top: 2vw;
  padding-bottom: 2vw;

  @media (max-width: 1124px) {
    width: 33vw;
    height: auto;
  }
  @media (max-width: 985px) {
    width: 50vw;
    height: auto;
  }
  @media (max-width: 650px) {
    width: 80vw;
    height: auto;
  }
`;
