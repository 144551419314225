import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  AcctiveColumn,
  AcctiveRow,
  ActiveDownContainer,
  ActiveUpperContainer,
  Modal,
  ModalButton,
  ModalButtonContainer,
  ModalContent,
  ModalText,
  ModificaButton,
  TableBodyActive,
  TableComponent,
  TableHeaderActive,
  TableHeaderCell,
} from "../TableStyle/Tablestyle";
import TicIcon from "../../Assets/ticicon.png";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { EditAutoPayment, getCardById } from "../../api/Axios";
import Layout from "../Layout/Layout";
import BottomBar from "../MisTarjetas/BottomBar";
import { Token } from "@mui/icons-material";

function ModificarForm() {
  const userid = localStorage.getItem("user_id");
  let maskedAccountNo = "";
  const { policy_no, date, amount, token } = useParams();
  const [date_, setDate] = useState("");
  const [carddata, setCardData] = useState([]);
  const [token_, setToken] = useState("");
  const [isOpenModal, setIsopenModal] = useState(false);
  const [datevalidation, setDatevalidation] = useState(false);
  const [cardvalidation, setCardvalidation] = useState(false);
  const [cvvvalidation, setCvvvalidation] = useState(false);
  const [cvv, setCvv] = useState("");
  const [cvvValied, setCvvValied] = useState(false);
  const [selectedCardNumber, setSelectedCardNumber] = useState("");
  useEffect(() => {
    if (
      localStorage.getItem("user_id") === null &&
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      window.location.href = `/`;
    }
  }, []);
  useEffect(() => {
    setDate(date);
    setToken(token);
  }, [date]);

  useEffect(() => {
    getCardById(userid)
      .then((res) => {
        setCardData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userid]);

  const handleSubmit = () => {
    const values = {
      merchant_id: "999999999999",
      token: token_,
      additional: "",
      date: date_,
      cardDetails: selectedCardNumber, // Include the selected card number
    };
    console.log(values);
    var issubmit = false;
    if (!date_) {
      setDatevalidation(true);
    }
    if (!token_) {
      setCardvalidation(true);
    }
    if (!cvvValied) {
      setCvvvalidation(true);
    }
    if (date_ && token_ && cvvValied) {
      issubmit = true;
    }
    if (issubmit) {
      EditAutoPayment(policy_no, values)
        .then((res) => {
          console.log(res);
          setIsopenModal(true);
        })
        .catch((err) => {
          console.log(err);
          alert("fail!");
        });
    } else {
      console.log("Something wrong in data entering");
    }
  };

  return (
    <Layout>
      <Grid
        columns={{ xs: 4, md: 12 }}
        sx={{
          margin: "20px 0 50px 0",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{
              fontSize: ["2rem", "2.5rem"],
              fontWeight: "900",
              color: "#0A6644",
              fontStyle: "normal",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            Modificar cargos recurrentes
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: ["1.5rem", "2rem"],
              fontWeight: "390px",
              color: "#0A6644",
              fontStyle: "normal",
              maxWidth: "77.835vw",
            }}
          >
            Pulse sobre la póliza para activar el cargo recurrente
          </Typography>
        </Grid>
        <Grid sx={{ margin: "0 5vw", display: ["", "", ""] }}>
          <Grid sx={{ width: ["100%", "100%", "75%"] }}>
            <TableComponent>
              <TableHeaderActive>
                <TableHeaderCell>Modificar cargo </TableHeaderCell>
              </TableHeaderActive>
              <TableBodyActive>
                <ActiveUpperContainer>
                  <label>Día de cargo</label>
                  <div>
                    <select
                      value={date_}
                      onChange={(e) => {
                        const newDate = e.target.value;
                        setDate(newDate);
                        if (newDate !== "") {
                          setDatevalidation(false);
                        }
                      }}
                    >
                      <option value="" disabled></option>
                      {Array.from({ length: 31 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                    {datevalidation && (
                      <p
                        style={{ color: "red", fontSize: "16px", margin: "0" }}
                      >
                        día de carga requerido
                      </p>
                    )}
                  </div>
                  <label>Monto a cargar</label>
                  <input type="text" value={amount} disabled />
                </ActiveUpperContainer>
                <Grid item xs={12}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: ["1rem", "1rem"],
                    fontWeight: "100",
                    color: "#0A6644",
                    fontStyle: "normal",
                    paddingLeft: "50px",
                  }}
                >
                  ** Estimado cliente, el cargo será procesado a las 9:00 pm del
                  día seleccionado.
                </Typography>
              </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: ["1.5rem", "2rem"],
                      fontWeight: "900",
                      color: "#0A6644",
                      fontStyle: "normal",
                      padding: "50px",
                    }}
                  >
                    Mis tarjetas:
                  </Typography>
                </Grid>
                <ActiveDownContainer>
                  {carddata.map((data) => {
                    const cvvNo = data.cvv_no;
                    const last4Digits = data.account_no.slice(-4);
                    maskedAccountNo =
                      "*".repeat(data.account_no.length - 4) + last4Digits;
                    return (
                      <AcctiveRow>
                        <AcctiveColumn>
                          <input
                            type="radio"
                            name="tic"
                            value={data.token}
                            checked={data.token === token_}
                            onChange={(e) => {
                              setToken(e.target.value);
                              const selectedCard = carddata.find(
                                (card) => card.token === e.target.value
                              );
                              if (selectedCard) {
                                const selectedCardNumber =
                                  selectedCard.account_no;
                                setSelectedCardNumber(selectedCardNumber);
                              } else {
                                setSelectedCardNumber("");
                              }
                            }}
                          />
                        </AcctiveColumn>
                        <AcctiveColumn>
                          {maskedAccountNo} <br />
                          Expira {data.expiration_date}
                        </AcctiveColumn>
                        <AcctiveColumn>
                          {data.token === token_ && (
                            <>
                              <label style={{ paddingLeft: "20px" }}>CVV</label>
                              <br />
                              <span>
                                <input
                                  type="text"
                                  name="cvv"
                                  maxLength={3}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    setCvv(inputValue);
                                    const formattedCVV = inputValue.slice(0, 3);
                                    setCvvvalidation(false);
                                    if (formattedCVV === cvvNo) {
                                      setCvvValied(true);
                                    } else {
                                      setCvvValied(false);
                                    }
                                  }}
                                />
                              </span>
                              {cvvvalidation && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                >
                                  CVV no válido
                                </p>
                              )}
                            </>
                          )}
                        </AcctiveColumn>
                      </AcctiveRow>
                    );
                  })}
                  {cardvalidation && (
                    <p style={{ color: "red", fontSize: "16px", margin: "0" }}>
                      tarjeta requerida
                    </p>
                  )}
                </ActiveDownContainer>
              </TableBodyActive>
            </TableComponent>
            <Typography
                  variant="p"
                  sx={{
                    fontSize: ["1rem", "1rem"],
                    fontWeight: "100",
                    color: "#0A6644",
                    fontStyle: "normal",
                  }}
                >
                 Sí un mes no cuenta con un día seleccionado, el cargo se realizará el día inmediato anterior.
                </Typography>
                
          </Grid>
         
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "1rem 0rem",
            }}
          >
            <Button
              disabled={!cvv}
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              sx={{
                top: ["0px", "0px", "-70px"],
                backgroundColor: "#0A6644",
                color: "#FFFFFF",
                width: "12rem",
                height: "3.5rem",
                fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
                fontWeight: "390px",
                borderRadius: "0.9rem",
                textTransform: "none",
                fontStyle: "normal",
                "&:hover": { backgroundColor: "#0A6644" },
              }}
            >
              <span variant="p" style={{ marginRight: "0.5rem" }}>
                Guardar
              </span>
              <img src={TicIcon} alt="right card" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <Modal isOpen={isOpenModal}>
        <ModalContent>
          <ModalText>Su cargo ha sido actualizado con éxito.</ModalText>
          <ModalButtonContainer>
            <ModalButton
              onClick={() => {
                setIsopenModal(false);
                window.location.href = "/modificarcargosrecurrentes";
              }}
            >
              Aceptar
            </ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>
      <BottomBar />
    </Layout>
  );
}

export default ModificarForm;
