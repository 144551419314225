import React, { useRef, useState } from "react";
import styled from "styled-components";
import { TextField, Button, Typography, Grid, Box } from "@mui/material";
import {
  Modal,
  ModalContent,
  ModalText,
  ModalButton,
  ModalButtonContainer,
} from "../TableStyle/Tablestyle";
import RegisterIcon1 from "../../Assets/Register/registericon1.png";
import RegisterIcon2 from "../../Assets/Register/registericon2.png";
import RegisterIcon3 from "../../Assets/Register/registericon3.png";
import RegisterIcon4 from "../../Assets/Register/registericon4.png";
import RegisterButton from "../../Assets/Register/registerbutton.png";
import PhoneInput from "react-phone-input-2"; // Make sure you've installed this package
import "react-phone-input-2/lib/style.css"; // Import the styles for PhoneInput
import { OtpModal, OtpModalContent } from "../TableStyle/Tablestyle";
import { auth } from "./firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Regestration } from "../../api/Axios";
import "./Register.css";

export default function Register() {
  const [name, setname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [errors, setErrors] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const phoneNumberRef = useRef(null);
  const faxRef = useRef(null);
  const countryCodeOptions = [
    { value: "+1", label: "United States (+1)" },
    { value: "+44", label: "United Kingdom (+44)" },
    // Add more country code options as needed
  ];

  const handleCountryCodeChange = (selectedOption) => {
    setCountryCode(selectedOption.value);
  };
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [openOtp, setopenOtp] = useState(false);
  const [isOpenModal, setIsopenModal] = useState(false);
  const [deleteAutoErrorAlertMessage, setDeleteAutoErrorAlertMessage] =
    useState("");

  const findFormErrors = () => {
    const newErrors = {};

    // Validate name
    if (!name || name === "") newErrors.name = "¡Se requiere el nombre!";
    else if (name.length > 30)
      newErrors.name = "¡El nombre es demasiado largo!";

    // Validate email
    if (!email || email === "")
      newErrors.email = "¡Correo electronico es requerido!";
    else if (!/\S+@\S+\.\S+/.test(email))
      newErrors.email = "¡El correo electrónico es invalido!";

    // Validate password
    if (!password || password === "") {
      newErrors.password = "¡Se requiere contraseña!";
    } else if (password.length < 8) {
      newErrors.password = "¡La contraseña debe tener al menos 8 caracteres!";
    } else if (!/[A-Z]/.test(password)) {
      newErrors.password =
        "¡La contraseña debe contener al menos una letra mayúscula!";
    } else if (!/[a-z]/.test(password)) {
      newErrors.password =
        "¡La contraseña debe contener al menos una letra minúscula!";
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
      newErrors.password =
        "¡La contraseña debe contener al menos un carácter especial!";
    }

    // Validate phone number
    if (!phone_number || phone_number === "") {
      newErrors.phone_number = "¡El número de teléfono es obligatorio!";
    } else if (!/^[0-9]+$/.test(phone_number)) {
      newErrors.phone_number =
        "¡El número de teléfono debe contener solo dígitos!";
    }
    // else if (phone_number.length !== 10) {
    //   newErrors.phone_number = "¡El número de teléfono debe tener 10 dígitos!";
    // }

    return newErrors;
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  const handleSubmit = async (e) => {
    // Validate the form data
    const newErrors = findFormErrors();
    setErrors(newErrors);
    // Check if there are no errors before submitting
    if (Object.keys(newErrors).length === 0) {
      handleSendVerification();
    }
  };

  const formattedPhoneNumber = "+" + phone_number;
  const handleSendVerification = async () => {
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        window.confirmationResult = confirmationResult;
        setopenOtp(true);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const handleOtpDigitChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const updatedOtpDigits = [...otpDigits];
      updatedOtpDigits[index] = value;
      setOtpDigits(updatedOtpDigits);
      // Move focus to the next input box, if available
      if (value && index < otpDigits.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleOtpSubmit = async () => {
    // otp varification code
    const formattedOtp = otpDigits.filter((item) => !isNaN(item));
    const concatenatedNumbers = formattedOtp.join("");
    console.log(concatenatedNumbers);
    // verifu otp
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(concatenatedNumbers)
      .then((result) => {
        let user = result.user;
        console.log("hello:", user);
        setTimeout(() => {
          window.recaptchaVerifier.clear();
          registerFunction();
        }, 100);
      })
      .catch((error) => {
        alert("Error verifying OTP:", error);
        // Close the reCAPTCHA widget
        window.location.href = `/register`;
      });
  };

  const registerFunction = async () => {
    // if otp validate then run bellow code
    const userData = {
      nombre_completo: name,
      phone_number: phone_number,
      email: email,
      password: password,
      login_status: "emailType",
      is_phone_auth: "1",
    };
    try {
      const res = await Regestration(userData); // Assuming Regestration is correctly defined
      console.log(res);
      if (res.data.success === true) {
        setDeleteAutoErrorAlertMessage("Registro exitoso");
        setIsopenModal(true);
      } else {
        setDeleteAutoErrorAlertMessage(res.data.message);
        setIsopenModal(true);
      }
    } catch (err) {
      setDeleteAutoErrorAlertMessage("registro fallido");
      setIsopenModal(true);
    }
  };

  return (
    <div>
      <RegisterFormWrapper>
        <form>
          <Grid
            container
            spacing={2}
            sx={{
              width: { md: "29.1vw", sm: "38.1vw", xs: "48.1vw" },
              margin: "auto",
              "& .MuiGrid-item": {
                paddingLeft: "0px",
                paddingTop: "0px",
              },
            }}
          >
            <Grid item xs={12}>
              {/* Register title */}
              <Typography
                component="h1"
                variant="h4"
                align="center"
                sx={{
                  color: "#0A6644",
                  fontSize: "1.5rem",
                  fontWeight: 650,
                  marginTop: "1vw",
                  marginBottom: "0.1vw",
                  fontFamily: "Brandon Grotesque",
                }}
              >
                <b>Registro</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Full name */}
              <TextField
                sx={TextfieldStyles}
                variant="outlined"
                margin="normal"
                id="username"
                label="Username"
                name="username"
                type="text"
                helperText={errors.name} // Add helperText to display error message
                error={errors.name !== undefined} // Add error prop to highlight the field
                value={name}
                onChange={(e) => setname(e.target.value)}
                autoComplete="username"
                placeholder="Nombre completo"
                InputProps={{
                  style: {
                    color: "#0A6644",
                    fontFamily: "Brandon Grotesque",
                  },
                  startAdornment: (
                    <img
                      src={RegisterIcon1}
                      style={{ height: "98%" }}
                      alt="Username"
                    />
                    // </div>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Email */}
              <TextField
                sx={TextfieldStyles}
                variant="outlined"
                margin="normal"
                id="email"
                label="email"
                name="email"
                type="email"
                value={email}
                helperText={errors.email}
                error={errors.email !== undefined}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                placeholder="Correo electrónico"
                InputProps={{
                  style: {
                    color: "#0A6644",
                    fontFamily: "Brandon Grotesque",
                  },
                  startAdornment: (
                    <div style={{ backgroundColor: "#0A6644", height: "98%" }}>
                      <img
                        src={RegisterIcon2}
                        style={{ height: "98%" }}
                        alt="Email"
                      />
                    </div>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Pass word */}
              <TextField
                sx={TextfieldStyles}
                variant="outlined"
                margin="normal"
                id="password"
                label="password"
                name="password"
                type="password"
                helperText={errors.password}
                error={errors.password !== undefined}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="password"
                placeholder="Contraseña"
                InputProps={{
                  style: {
                    color: "#0A6644",
                    fontFamily: "Brandon Grotesque",
                  },
                  startAdornment: (
                    <div style={{ backgroundColor: "#0A6644", height: "98%" }}>
                      <img
                        src={RegisterIcon3}
                        style={{
                          height: "98%",
                        }}
                        alt="password"
                      />
                    </div>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={120}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "0.9rem",
              }}
            >
              <Country>
                <PhoneInput
                  country={"es"}
                  inputRef={phoneNumberRef}
                  inputProps={{
                    onKeyDown: (event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        faxRef.current.focus();
                      }
                    },
                  }}
                  //country="us" // Default country code
                  enableAreaCodes={true}
                  placeholder="Teléfono"
                  value={phone_number}
                  onChange={(phone_number) => setphone_number(phone_number)}
                  // onBlur={handlePrimaryPhoneBlur}
                  error={errors.phone_number !== undefined}
                  variant="outlined"
                  inputClass="phone_input"
                  //                   containerStyle={{
                  //                     boader: "3px solid !important",
                  //                   }}

                  inputStyle={{
                    display: "flex",
                    fontFamily: "Roboto",
                    border: "3px solid #0A6644", // Updated border style
                    backgroundColor: "transparent", // Transparent background
                    color: "#0A6644", // Text color
                    fontSize: "1.4rem", // Font size
                    paddingLeft: "160px", // Padding on the left
                  }}
                />
              </Country>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Correct data button */}
              <ButtonWrapper>
                <Button
                  onClick={handleSubmit}
                  sx={{
                    ...buttonstyle,
                    "@media (max-width: 1124px)": {
                      width: "21vw",
                      // width: "80%",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 985px)": {
                      width: "22vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 950px)": {
                      width: "23vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 910px)": {
                      width: "24vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 875px)": {
                      width: "30vw",
                      fontSize: "0.9rem",
                    },
                    "@media (max-width: 700px)": {
                      width: "35vw",
                      fontSize: "0.9rem",
                    },
                  }}
                  variant="contained"
                  onSubmit={handleSubmit}
                  endIcon={
                    <img
                      src={RegisterButton}
                      alt="RegisterButton"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  }
                >
                  Datos correctos
                </Button>
                <Typography
                  sx={{
                    color: "#0A6644",
                    fontSize: "0.8rem",
                    textAlign: "center",
                    marginTop: "0rem",
                    alignContent: "center",
                    justifyContent: "center",
                    fontFamily: "Brandon Grotesque",
                    // marginLeft: "1rem",
                  }}
                >
                  <span style={{ fontWeight: 300 }}>
                    Al Registrarse acepta nuestra{" "}
                  </span>
                  <span style={{ fontWeight: 800 }}>
                    <b>Política de Privacidad</b>
                  </span>
                </Typography>
              </ButtonWrapper>
            </Grid>
          </Grid>
        </form>
        <br />
      </RegisterFormWrapper>
      <div id="recaptcha"></div>
      {openOtp && (
        <OtpModal>
          <OtpModalContent>
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
              sx={{ padding: "2rem", textAlign: "center" }}
            >
              <Typography
                variant="p"
                style={{
                  fontSize: "30px",
                  color: "#0a6644",
                  paddingBottom: "10px",
                  fontWeight: "900",
                }}
              >
                Ingrese el código OTP
              </Typography>
              <Box display="flex" justifyContent="center">
                {otpDigits.map((digit, index) => (
                  <input
                    key={index}
                    type="tel"
                    pattern="[0-9]*"
                    maxLength={1}
                    id={`otp-input-${index}`}
                    value={digit}
                    onChange={(e) =>
                      handleOtpDigitChange(index, e.target.value)
                    }
                    style={{
                      width: "2rem",
                      height: "2rem",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      margin: "0.5rem",
                      border: "1px solid #0a6644",
                      borderRadius: "10px",
                      color: "#0a6644",
                    }}
                  />
                ))}
              </Box>
              <Button
                style={{
                  backgroundColor: "#0a6644",
                  color: "white",
                  marginTop: "10px",
                  borderRadius: "10px",
                }}
                variant="contained"
                onClick={handleOtpSubmit}
                disabled={otpDigits.some((digit) => digit === "")}
              >
                Verificar OTP
              </Button>
            </Grid>
          </OtpModalContent>
        </OtpModal>
      )}
      <Modal isOpen={isOpenModal}>
        <ModalContent>
          <ModalText>{deleteAutoErrorAlertMessage}</ModalText>
          <ModalButtonContainer>
            <ModalButton
              onClick={() => {
                setIsopenModal(false);
                if (deleteAutoErrorAlertMessage == "registración exitosa") {
                  window.location.href = `/`;
                } else {
                  window.location.href = `/register`;
                }
              }}
            >
              Aceptar
            </ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>
    </div>
  );
}

const RegisterFormWrapper = styled.div`
  width: 34vw;
  height: auto;
  border: 3px solid #aecb35;
  border-radius: 20px;
  margin: auto;
  margin-top: 2vw;

  @media (max-width: 1124px) {
    width: 33vw;
    height: auto;
  }
  @media (max-width: 985px) {
    width: 50vw;
    height: auto;
  }
  @media (max-width: 650px) {
    width: 80vw;
    height: auto;
  }
`;

const DropStyles = {
  "& .MuiFormControl-root": {
    "& .MuiTextField-root": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#0A6644",
    opacity: 1, // Set opacity to 1 to ensure the color is fully visible
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #0A6644",
    backgroundColor: "transparent",
    padding: "0 1px",
    top: "-4px",
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiInputBase-root ": {
    paddingLeft: "0px",
    width: { md: "2vw", sm: "38vw", xs: "48vw" },
    height: { md: "7.5vh", sm: "6vh", xs: "5vh" },
    // marginBottom: "1vh",
  },
  "& .MuiInputBase-input": {
    paddingLeft: "1.25rem",
    fontSize: "1.4rem",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "3px solid #0A6644",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "3px solid #0A6644",
    },
  },
  "& fieldset": {
    borderColor: "#0A6644", // Set the border color for the default state
    legend: {
      width: "0px",
    },
  },
  "&:hover fieldset": {
    borderColor: "#0A6644", // Set the border color for the hover state
  },
};
const TextfieldStyl = {
  "& .MuiFormControl-root": {
    "& .MuiTextField-root": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#0A6644",
    opacity: 1, // Set opacity to 1 to ensure the color is fully visible
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #0A6644",
    backgroundColor: "transparent",
    padding: "0 1px",
    top: "-4px",
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiInputBase-root ": {
    paddingLeft: "0px",
    width: { md: "21vw", sm: "38vw", xs: "48vw" },
    height: { md: "7.5vh", sm: "6vh", xs: "5vh" },
    // marginBottom: "1vh",
  },
  "& .MuiInputBase-input": {
    paddingLeft: "1.25rem",
    fontSize: "1.4rem",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "3px solid #0A6644",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "3px solid #0A6644",
    },
  },
  "& fieldset": {
    borderColor: "#0A6644", // Set the border color for the default state
    legend: {
      width: "0px",
    },
  },
  "&:hover fieldset": {
    borderColor: "#0A6644", // Set the border color for the hover state
  },
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  /* width: 20vw; */
  margin: auto;
  padding-top: 1.5vw;
`;

const buttonstyle = {
  backgroundColor: "#0A6644",
  width: "20vw",
  height: "7vh",
  fontSize: "1rem",
  boxshadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "0px",
  fontFamily: "Brandon Grotesque",
  "&:hover": {
    backgroundColor: "#0A6644",
  },
  margin: "0 auto",
};
const Country = styled.div`
  .react-tel-input .form-control {@media (max-width: 1444px) {
    width: 29vw;
  }
  @media (max-width: 1024px) {
    padding-left: 130px;
  }
  // @media (max-width: 905px) {
  //   width: 38vw;
  //   height: 5.8vh;
  // }
  @media (max-width: 330px) {
    width: 38vw;
    height: 5.8vh;
  }

  border: 3px solid #0a6644 !important;
  width: 29vw;
  height: 7.5vh;
}

.react-tel-input .flag-dropdown {
  @media (max-width: 768px) {
    width: 60px;
  }
  @media (max-width: 425px) {
    width: 50px;
  }
  top: 0;
  bottom: 0;
  width: 77px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${RegisterIcon4}) !important;
  border: 3px #0a6644 !important;
}
.kPDUHs .react-tel-input .flag-dropdown {
  top: 0px;
  bottom: 0px;

  background-color: rgb(10, 102, 68) !important;
  border: 3px rgb(10, 102, 68) !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  display: flex;
  width: inherit;
  border-radius: 3px 0 0 0;
}
.react-tel-input .selected-flag:focus {
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  position: relative;
  width: inherit;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
}
.ehjwoZ .react-tel-input .flag-dropdown.open .selected-flag {
  background: rgb(10, 102, 68);
  border-radius: 3px 0px 0px;
}
.react-tel-input .selected-flag:hover .react-tel-input .selected-flag:focus {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  left: 50px;
  border-radius: 3px 0 0 3px;
}



@media (max-width: 2560px) {
  .react-tel-input .form-control {
    padding-left: 125px !important;
  }
  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    left: 90px;
    border-radius: 3px 0 0 3px;
  }
}

@media (max-width: 1440px) {
  .react-tel-input .form-control {
    padding-left: 125px !important;
  }
  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    left: 90px;
    border-radius: 3px 0 0 3px;
  }
}

@media (max-width: 1024px) {
  .react-tel-input .form-control {
    padding-left: 125px !important;
  }
  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    left: 80px;
    border-radius: 3px 0 0 3px;
  }
  }

  @media (max-width: 425px) {
    .react-tel-input .form-control {
      padding-left: 90px !important;
    }
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      left:50px;
      border-radius: 3px 0 0 3px;
    }
    }

  @media (max-width: 320px) {
    .react-tel-input .form-control {
      padding-left: 90px !important;
    }
    .react-tel-input .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      left:50px;
      border-radius: 3px 0 0 3px;
    }
    }

  @media (max-width: 576px) {
    .react-tel-input .form-control {
      width: 48vw; // Adjust width for extra small screens
      height: 1vh; // Adjust height for extra small screens
    }
  }
`;

const TextfieldStyles = {
  "& .MuiFormControl-root": {
    "& .MuiTextField-root": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#0A6644",
    opacity: 1, // Set opacity to 1 to ensure the color is fully visible
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #0A6644",
    backgroundColor: "transparent",
    padding: "0 1px",
    top: "-4px",
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiInputBase-root ": {
    paddingLeft: "0px",
    width: { md: "29vw", sm: "38vw", xs: "48vw" },
    height: { md: "7.5vh", sm: "6vh", xs: "5vh" },
    // marginBottom: "1vh",
  },
  "& .MuiInputBase-input": {
    paddingLeft: "1.25rem",
    fontSize: "1.4rem",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "3px solid #0A6644",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "3px solid #0A6644",
    },
  },
  "& fieldset": {
    borderColor: "#0A6644", // Set the border color for the default state
    legend: {
      width: "0px",
    },
  },
  "&:hover fieldset": {
    borderColor: "#0A6644", // Set the border color for the hover state
  },
};
